@charset "UTF-8";
/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "\2190";
}
[dir=rtl] .slick-prev:before {
  content: "\2192";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "\2192";
}
[dir=rtl] .slick-next:before {
  content: "\2190";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\2022";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.fancybox-caption__body {
  font-family: "gtWalsheim", sans-serif;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden;
}

body {
  line-height: 1;
  overflow-x: hidden;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

strong, b {
  font-weight: bold;
}

blockquote, q {
  quotes: none;
}

input:focus {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important;
}

a {
  -webkit-tap-highlight-color: transparent;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strike, strong, sub, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time,
mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active,
button:hover, label:focus, .btn:active, .btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
}

textarea, input, input[type=text], input[type=button], input[type=submit] {
  outline: none;
  -webkit-appearance: none !important;
  border-radius: 0px !important;
  outline: none !important;
}

button, input[type=submit], input[type=reset] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill,
textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  border-bottom: 1px solid #d6d8da;
  -webkit-text-fill-color: #343b48;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}

.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.grid.gaps {
  width: calc(100% + 1% + 1%);
  margin-left: -1%;
  margin-right: -1%;
}
.grid.gaps .column {
  padding-right: 1%;
  padding-left: 1%;
}

.col1 {
  width: 8.3333333333%;
}

.col2 {
  width: 16.6666666667%;
}

.col3 {
  width: 25%;
}

.col4 {
  width: 33.3333333333%;
}

.col5 {
  width: 41.6666666667%;
}

.col6 {
  width: 50%;
}

.col7 {
  width: 58.3333333333%;
}

.col8 {
  width: 66.6666666667%;
}

.col9 {
  width: 75%;
}

.col10 {
  width: 83.3333333333%;
}

.col11 {
  width: 91.6666666667%;
}

.col12 {
  width: 100%;
}

@media screen and (max-width: 1800px) {
  .col1_1800 {
    width: 8.3333333333%;
  }
  .col2_1800 {
    width: 16.6666666667%;
  }
  .col3_1800 {
    width: 25%;
  }
  .col4_1800 {
    width: 33.3333333333%;
  }
  .col5_1800 {
    width: 41.6666666667%;
  }
  .col6_1800 {
    width: 50%;
  }
  .col7_1800 {
    width: 58.3333333333%;
  }
  .col8_1800 {
    width: 66.6666666667%;
  }
  .col9_1800 {
    width: 75%;
  }
  .col10_1800 {
    width: 83.3333333333%;
  }
  .col11_1800 {
    width: 91.6666666667%;
  }
  .col12_1800 {
    width: 100%;
  }
}
@media screen and (max-width: 1700px) {
  .col1_1700 {
    width: 8.3333333333%;
  }
  .col2_1700 {
    width: 16.6666666667%;
  }
  .col3_1700 {
    width: 25%;
  }
  .col4_1700 {
    width: 33.3333333333%;
  }
  .col5_1700 {
    width: 41.6666666667%;
  }
  .col6_1700 {
    width: 50%;
  }
  .col7_1700 {
    width: 58.3333333333%;
  }
  .col8_1700 {
    width: 66.6666666667%;
  }
  .col9_1700 {
    width: 75%;
  }
  .col10_1700 {
    width: 83.3333333333%;
  }
  .col11_1700 {
    width: 91.6666666667%;
  }
  .col12_1700 {
    width: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .col1_1600 {
    width: 8.3333333333%;
  }
  .col2_1600 {
    width: 16.6666666667%;
  }
  .col3_1600 {
    width: 25%;
  }
  .col4_1600 {
    width: 33.3333333333%;
  }
  .col5_1600 {
    width: 41.6666666667%;
  }
  .col6_1600 {
    width: 50%;
  }
  .col7_1600 {
    width: 58.3333333333%;
  }
  .col8_1600 {
    width: 66.6666666667%;
  }
  .col9_1600 {
    width: 75%;
  }
  .col10_1600 {
    width: 83.3333333333%;
  }
  .col11_1600 {
    width: 91.6666666667%;
  }
  .col12_1600 {
    width: 100%;
  }
}
@media screen and (max-width: 1500px) {
  .col1_1500 {
    width: 8.3333333333%;
  }
  .col2_1500 {
    width: 16.6666666667%;
  }
  .col3_1500 {
    width: 25%;
  }
  .col4_1500 {
    width: 33.3333333333%;
  }
  .col5_1500 {
    width: 41.6666666667%;
  }
  .col6_1500 {
    width: 50%;
  }
  .col7_1500 {
    width: 58.3333333333%;
  }
  .col8_1500 {
    width: 66.6666666667%;
  }
  .col9_1500 {
    width: 75%;
  }
  .col10_1500 {
    width: 83.3333333333%;
  }
  .col11_1500 {
    width: 91.6666666667%;
  }
  .col12_1500 {
    width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .col1_1400 {
    width: 8.3333333333%;
  }
  .col2_1400 {
    width: 16.6666666667%;
  }
  .col3_1400 {
    width: 25%;
  }
  .col4_1400 {
    width: 33.3333333333%;
  }
  .col5_1400 {
    width: 41.6666666667%;
  }
  .col6_1400 {
    width: 50%;
  }
  .col7_1400 {
    width: 58.3333333333%;
  }
  .col8_1400 {
    width: 66.6666666667%;
  }
  .col9_1400 {
    width: 75%;
  }
  .col10_1400 {
    width: 83.3333333333%;
  }
  .col11_1400 {
    width: 91.6666666667%;
  }
  .col12_1400 {
    width: 100%;
  }
}
@media screen and (max-width: 1300px) {
  .col1_1300 {
    width: 8.3333333333%;
  }
  .col2_1300 {
    width: 16.6666666667%;
  }
  .col3_1300 {
    width: 25%;
  }
  .col4_1300 {
    width: 33.3333333333%;
  }
  .col5_1300 {
    width: 41.6666666667%;
  }
  .col6_1300 {
    width: 50%;
  }
  .col7_1300 {
    width: 58.3333333333%;
  }
  .col8_1300 {
    width: 66.6666666667%;
  }
  .col9_1300 {
    width: 75%;
  }
  .col10_1300 {
    width: 83.3333333333%;
  }
  .col11_1300 {
    width: 91.6666666667%;
  }
  .col12_1300 {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .col1_1200 {
    width: 8.3333333333%;
  }
  .col2_1200 {
    width: 16.6666666667%;
  }
  .col3_1200 {
    width: 25%;
  }
  .col4_1200 {
    width: 33.3333333333%;
  }
  .col5_1200 {
    width: 41.6666666667%;
  }
  .col6_1200 {
    width: 50%;
  }
  .col7_1200 {
    width: 58.3333333333%;
  }
  .col8_1200 {
    width: 66.6666666667%;
  }
  .col9_1200 {
    width: 75%;
  }
  .col10_1200 {
    width: 83.3333333333%;
  }
  .col11_1200 {
    width: 91.6666666667%;
  }
  .col12_1200 {
    width: 100%;
  }
}
@media screen and (max-width: 1100px) {
  .col1_1100 {
    width: 8.3333333333%;
  }
  .col2_1100 {
    width: 16.6666666667%;
  }
  .col3_1100 {
    width: 25%;
  }
  .col4_1100 {
    width: 33.3333333333%;
  }
  .col5_1100 {
    width: 41.6666666667%;
  }
  .col6_1100 {
    width: 50%;
  }
  .col7_1100 {
    width: 58.3333333333%;
  }
  .col8_1100 {
    width: 66.6666666667%;
  }
  .col9_1100 {
    width: 75%;
  }
  .col10_1100 {
    width: 83.3333333333%;
  }
  .col11_1100 {
    width: 91.6666666667%;
  }
  .col12_1100 {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .col1_1024 {
    width: 8.3333333333%;
  }
  .col2_1024 {
    width: 16.6666666667%;
  }
  .col3_1024 {
    width: 25%;
  }
  .col4_1024 {
    width: 33.3333333333%;
  }
  .col5_1024 {
    width: 41.6666666667%;
  }
  .col6_1024 {
    width: 50%;
  }
  .col7_1024 {
    width: 58.3333333333%;
  }
  .col8_1024 {
    width: 66.6666666667%;
  }
  .col9_1024 {
    width: 75%;
  }
  .col10_1024 {
    width: 83.3333333333%;
  }
  .col11_1024 {
    width: 91.6666666667%;
  }
  .col12_1024 {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .col1_900 {
    width: 8.3333333333%;
  }
  .col2_900 {
    width: 16.6666666667%;
  }
  .col3_900 {
    width: 25%;
  }
  .col4_900 {
    width: 33.3333333333%;
  }
  .col5_900 {
    width: 41.6666666667%;
  }
  .col6_900 {
    width: 50%;
  }
  .col7_900 {
    width: 58.3333333333%;
  }
  .col8_900 {
    width: 66.6666666667%;
  }
  .col9_900 {
    width: 75%;
  }
  .col10_900 {
    width: 83.3333333333%;
  }
  .col11_900 {
    width: 91.6666666667%;
  }
  .col12_900 {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .col1_768 {
    width: 8.3333333333%;
  }
  .col2_768 {
    width: 16.6666666667%;
  }
  .col3_768 {
    width: 25%;
  }
  .col4_768 {
    width: 33.3333333333%;
  }
  .col5_768 {
    width: 41.6666666667%;
  }
  .col6_768 {
    width: 50%;
  }
  .col7_768 {
    width: 58.3333333333%;
  }
  .col8_768 {
    width: 66.6666666667%;
  }
  .col9_768 {
    width: 75%;
  }
  .col10_768 {
    width: 83.3333333333%;
  }
  .col11_768 {
    width: 91.6666666667%;
  }
  .col12_768 {
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .col1_700 {
    width: 8.3333333333%;
  }
  .col2_700 {
    width: 16.6666666667%;
  }
  .col3_700 {
    width: 25%;
  }
  .col4_700 {
    width: 33.3333333333%;
  }
  .col5_700 {
    width: 41.6666666667%;
  }
  .col6_700 {
    width: 50%;
  }
  .col7_700 {
    width: 58.3333333333%;
  }
  .col8_700 {
    width: 66.6666666667%;
  }
  .col9_700 {
    width: 75%;
  }
  .col10_700 {
    width: 83.3333333333%;
  }
  .col11_700 {
    width: 91.6666666667%;
  }
  .col12_700 {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .col1_600 {
    width: 8.3333333333%;
  }
  .col2_600 {
    width: 16.6666666667%;
  }
  .col3_600 {
    width: 25%;
  }
  .col4_600 {
    width: 33.3333333333%;
  }
  .col5_600 {
    width: 41.6666666667%;
  }
  .col6_600 {
    width: 50%;
  }
  .col7_600 {
    width: 58.3333333333%;
  }
  .col8_600 {
    width: 66.6666666667%;
  }
  .col9_600 {
    width: 75%;
  }
  .col10_600 {
    width: 83.3333333333%;
  }
  .col11_600 {
    width: 91.6666666667%;
  }
  .col12_600 {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .col1_500 {
    width: 8.3333333333%;
  }
  .col2_500 {
    width: 16.6666666667%;
  }
  .col3_500 {
    width: 25%;
  }
  .col4_500 {
    width: 33.3333333333%;
  }
  .col5_500 {
    width: 41.6666666667%;
  }
  .col6_500 {
    width: 50%;
  }
  .col7_500 {
    width: 58.3333333333%;
  }
  .col8_500 {
    width: 66.6666666667%;
  }
  .col9_500 {
    width: 75%;
  }
  .col10_500 {
    width: 83.3333333333%;
  }
  .col11_500 {
    width: 91.6666666667%;
  }
  .col12_500 {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .col1_425 {
    width: 8.3333333333%;
  }
  .col2_425 {
    width: 16.6666666667%;
  }
  .col3_425 {
    width: 25%;
  }
  .col4_425 {
    width: 33.3333333333%;
  }
  .col5_425 {
    width: 41.6666666667%;
  }
  .col6_425 {
    width: 50%;
  }
  .col7_425 {
    width: 58.3333333333%;
  }
  .col8_425 {
    width: 66.6666666667%;
  }
  .col9_425 {
    width: 75%;
  }
  .col10_425 {
    width: 83.3333333333%;
  }
  .col11_425 {
    width: 91.6666666667%;
  }
  .col12_425 {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .col1_375 {
    width: 8.3333333333%;
  }
  .col2_375 {
    width: 16.6666666667%;
  }
  .col3_375 {
    width: 25%;
  }
  .col4_375 {
    width: 33.3333333333%;
  }
  .col5_375 {
    width: 41.6666666667%;
  }
  .col6_375 {
    width: 50%;
  }
  .col7_375 {
    width: 58.3333333333%;
  }
  .col8_375 {
    width: 66.6666666667%;
  }
  .col9_375 {
    width: 75%;
  }
  .col10_375 {
    width: 83.3333333333%;
  }
  .col11_375 {
    width: 91.6666666667%;
  }
  .col12_375 {
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
  .col1_320 {
    width: 8.3333333333%;
  }
  .col2_320 {
    width: 16.6666666667%;
  }
  .col3_320 {
    width: 25%;
  }
  .col4_320 {
    width: 33.3333333333%;
  }
  .col5_320 {
    width: 41.6666666667%;
  }
  .col6_320 {
    width: 50%;
  }
  .col7_320 {
    width: 58.3333333333%;
  }
  .col8_320 {
    width: 66.6666666667%;
  }
  .col9_320 {
    width: 75%;
  }
  .col10_320 {
    width: 83.3333333333%;
  }
  .col11_320 {
    width: 91.6666666667%;
  }
  .col12_320 {
    width: 100%;
  }
}
@font-face {
  font-family: "helveticaNeue";
  src: url("../fonts/helveticaNeue/helveticaNeueMedium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "helveticaNeue";
  src: url("../fonts/helveticaNeue/helveticaNeueBold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "gtWalsheim";
  src: url("../fonts/gtWalsheim/gtWalsheimLight.woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "gtWalsheim";
  src: url("../fonts/gtWalsheim/gtWalsheimRegular.woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "gtWalsheim";
  src: url("../fonts/gtWalsheim/gtWalsheimMedium.woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "gtWalsheim";
  src: url("../fonts/gtWalsheim/gtWalsheimBold.woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.table {
  display: table;
}

.tableCell {
  display: table-cell;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.alignTop {
  vertical-align: top;
}

.alignMiddle {
  vertical-align: middle;
}

.alignBottom {
  vertical-align: bottom;
}

.caps {
  text-transform: capitalize;
}

.allCaps {
  text-transform: uppercase;
}

.w1500 {
  max-width: 1500px;
  margin: 0 auto;
}
@media screen and (max-width: 1500px) {
  .w1500 {
    margin: 0;
  }
}

.w1200 {
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .w1200 {
    margin: 0;
  }
}

.w1100 {
  max-width: 1100px;
  margin: 0 auto;
}
@media screen and (max-width: 1100px) {
  .w1100 {
    margin: 0;
  }
}

.w1000 {
  max-width: 1000px;
  margin: 0 auto;
}
@media screen and (max-width: 1000px) {
  .w1000 {
    margin: 0;
  }
}

.w800 {
  max-width: 800px;
  margin: 0 auto;
}
@media screen and (max-width: 800px) {
  .w800 {
    margin: 0;
  }
}

.bm50 {
  margin-bottom: 50px;
}
@media screen and (max-width: 1200px) {
  .bm50 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .bm50 {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .bm50 {
    margin-bottom: 20px;
  }
}

.yp150 {
  padding-top: 150px;
  padding-bottom: 150px;
}
@media screen and (max-width: 1400px) {
  .yp150 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
@media screen and (max-width: 1200px) {
  .yp150 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
@media screen and (max-width: 1024px) {
  .yp150 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 768px) {
  .yp150 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .yp150 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.tp150 {
  padding-top: 150px;
}
@media screen and (max-width: 1400px) {
  .tp150 {
    padding-top: 130px;
  }
}
@media screen and (max-width: 1200px) {
  .tp150 {
    padding-top: 110px;
  }
}
@media screen and (max-width: 1024px) {
  .tp150 {
    padding-top: 90px;
  }
}
@media screen and (max-width: 768px) {
  .tp150 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 500px) {
  .tp150 {
    padding-top: 50px;
  }
}

.bp150 {
  padding-bottom: 150px;
}
@media screen and (max-width: 1400px) {
  .bp150 {
    padding-bottom: 130px;
  }
}
@media screen and (max-width: 1200px) {
  .bp150 {
    padding-bottom: 110px;
  }
}
@media screen and (max-width: 1024px) {
  .bp150 {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 768px) {
  .bp150 {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .bp150 {
    padding-bottom: 50px;
  }
}

.yp300 {
  padding-top: 300px;
  padding-bottom: 300px;
}
@media screen and (max-width: 1600px) {
  .yp300 {
    padding-top: 260px;
    padding-bottom: 260px;
  }
}
@media screen and (max-width: 1400px) {
  .yp300 {
    padding-top: 220px;
    padding-bottom: 220px;
  }
}
@media screen and (max-width: 1200px) {
  .yp300 {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}
@media screen and (max-width: 1024px) {
  .yp300 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
@media screen and (max-width: 768px) {
  .yp300 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 500px) {
  .yp300 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.tp300 {
  padding-top: 300px;
}
@media screen and (max-width: 1600px) {
  .tp300 {
    padding-top: 260px;
  }
}
@media screen and (max-width: 1400px) {
  .tp300 {
    padding-top: 220px;
  }
}
@media screen and (max-width: 1200px) {
  .tp300 {
    padding-top: 180px;
  }
}
@media screen and (max-width: 1024px) {
  .tp300 {
    padding-top: 130px;
  }
}
@media screen and (max-width: 768px) {
  .tp300 {
    padding-top: 90px;
  }
}
@media screen and (max-width: 500px) {
  .tp300 {
    padding-top: 50px;
  }
}

.bp300 {
  padding-bottom: 300px;
}
@media screen and (max-width: 1600px) {
  .bp300 {
    padding-bottom: 260px;
  }
}
@media screen and (max-width: 1400px) {
  .bp300 {
    padding-bottom: 220px;
  }
}
@media screen and (max-width: 1200px) {
  .bp300 {
    padding-bottom: 180px;
  }
}
@media screen and (max-width: 1024px) {
  .bp300 {
    padding-bottom: 130px;
  }
}
@media screen and (max-width: 768px) {
  .bp300 {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 500px) {
  .bp300 {
    padding-bottom: 50px;
  }
}

.yp200 {
  padding-top: 200px;
  padding-bottom: 200px;
}
@media screen and (max-width: 1400px) {
  .yp200 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .yp200 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yp200 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yp200 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .yp200 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.tp200 {
  padding-top: 200px;
}
@media screen and (max-width: 1400px) {
  .tp200 {
    padding-top: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .tp200 {
    padding-top: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .tp200 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .tp200 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 500px) {
  .tp200 {
    padding-top: 50px;
  }
}

.bp200 {
  padding-bottom: 200px;
}
@media screen and (max-width: 1400px) {
  .bp200 {
    padding-bottom: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .bp200 {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .bp200 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .bp200 {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .bp200 {
    padding-bottom: 50px;
  }
}

.yp120 {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media screen and (max-width: 1400px) {
  .yp120 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .yp120 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .yp120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yp120 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .yp120 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.tp120 {
  padding-top: 120px;
}
@media screen and (max-width: 1400px) {
  .tp120 {
    padding-top: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .tp120 {
    padding-top: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .tp120 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .tp120 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 500px) {
  .tp120 {
    padding-top: 50px;
  }
}

.bp120 {
  padding-bottom: 120px;
}
@media screen and (max-width: 1400px) {
  .bp120 {
    padding-bottom: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .bp120 {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .bp120 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .bp120 {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .bp120 {
    padding-bottom: 50px;
  }
}

.yp100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media screen and (max-width: 1024px) {
  .yp100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .yp100 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .yp100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.tp100 {
  padding-top: 100px;
}
@media screen and (max-width: 1024px) {
  .tp100 {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .tp100 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 500px) {
  .tp100 {
    padding-top: 50px;
  }
}

.bp100 {
  padding-bottom: 100px;
}
@media screen and (max-width: 1024px) {
  .bp100 {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .bp100 {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .bp100 {
    padding-bottom: 50px;
  }
}

.yp80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .yp80 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .yp80 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.tp80 {
  padding-top: 80px;
}
@media screen and (max-width: 768px) {
  .tp80 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 500px) {
  .tp80 {
    padding-top: 50px;
  }
}

.bp80 {
  padding-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .bp80 {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .bp80 {
    padding-bottom: 50px;
  }
}

.yp50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.tp50 {
  padding-top: 50px;
}

.bp50 {
  padding-bottom: 50px;
}

.xp160 {
  padding-right: 160px;
  padding-left: 160px;
}
@media screen and (max-width: 1400px) {
  .xp160 {
    padding-right: 130px;
    padding-left: 130px;
  }
}
@media screen and (max-width: 1200px) {
  .xp160 {
    padding-right: 100px;
    padding-left: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .xp160 {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .xp160 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .xp160 {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.rp160 {
  padding-right: 160px;
}
@media screen and (max-width: 1400px) {
  .rp160 {
    padding-right: 130px;
  }
}
@media screen and (max-width: 1200px) {
  .rp160 {
    padding-right: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .rp160 {
    padding-right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .rp160 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .rp160 {
    padding-right: 25px;
  }
}

.lp160 {
  padding-left: 160px;
}
@media screen and (max-width: 1400px) {
  .lp160 {
    padding-left: 130px;
  }
}
@media screen and (max-width: 1200px) {
  .lp160 {
    padding-left: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .lp160 {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .lp160 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .lp160 {
    padding-left: 25px;
  }
}

.xp120 {
  padding-right: 120px;
  padding-left: 120px;
}
@media screen and (max-width: 1200px) {
  .xp120 {
    padding-right: 100px;
    padding-left: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .xp120 {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .xp120 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .xp120 {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.rp120 {
  padding-right: 120px;
}
@media screen and (max-width: 1200px) {
  .rp120 {
    padding-right: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .rp120 {
    padding-right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .rp120 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .rp120 {
    padding-right: 25px;
  }
}

.lp120 {
  padding-left: 120px;
}
@media screen and (max-width: 1200px) {
  .lp120 {
    padding-left: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .lp120 {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .lp120 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .lp120 {
    padding-left: 25px;
  }
}

.xp100 {
  padding-right: 100px;
  padding-left: 100px;
}
@media screen and (max-width: 1024px) {
  .xp100 {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .xp100 {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .xp100 {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.rp100 {
  padding-right: 100px;
}
@media screen and (max-width: 1024px) {
  .rp100 {
    padding-right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .rp100 {
    padding-right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .rp100 {
    padding-right: 25px;
  }
}

.lp100 {
  padding-left: 100px;
}
@media screen and (max-width: 1024px) {
  .lp100 {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .lp100 {
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .lp100 {
    padding-left: 25px;
  }
}

.nowrap {
  white-space: nowrap;
}

.clearfix {
  display: block;
  clear: both;
}

.noScroll {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
  .noScroll {
    overflow-y: hidden;
  }
}

.noCustomScroll {
  overflow-y: hidden;
}
@media screen and (max-width: 768px) {
  .noCustomScroll {
    overflow-y: hidden;
  }
}

.noselect {
  user-select: none;
}

.hide {
  display: none;
}

@media screen and (max-width: 1800px) {
  .hide_1800 {
    display: none;
  }
}
@media screen and (max-width: 1700px) {
  .hide_1700 {
    display: none;
  }
}
@media screen and (max-width: 1600px) {
  .hide_1600 {
    display: none;
  }
}
@media screen and (max-width: 1500px) {
  .hide_1500 {
    display: none;
  }
}
@media screen and (max-width: 1400px) {
  .hide_1400 {
    display: none;
  }
}
@media screen and (max-width: 1300px) {
  .hide_1300 {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .hide_1200 {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .hide_1100 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .hide_1024 {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .hide_1000 {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .hide_900 {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .hide_800 {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .hide_768 {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .hide_700 {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .hide_600 {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .hide_500 {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .hide_550 {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .hide_400 {
    display: none;
  }
}
@media screen and (max-width: 300px) {
  .hide_300 {
    display: none;
  }
}
picture, figure {
  display: block;
  font-size: 0;
}
picture img, figure img {
  width: 100%;
  height: 100%;
}
picture.adaptive, figure.adaptive {
  position: relative;
  width: 100%;
  height: 100%;
}
picture.adaptive img, figure.adaptive img {
  position: absolute;
  top: 0;
  left: 0;
}

[data-object-fit=cover] {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.scroller body {
  overflow-x: hidden;
  height: 100vh;
}
.scroller [data-scroller] {
  overflow-x: hidden;
  height: 100vh;
}
.scroller [data-scroller] [data-scroller-content] {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  overflow-x: hidden;
}
.scroller [data-height] {
  position: absolute;
  width: 1px;
  top: 0;
}

.hide {
  display: none;
}

h1, .h1 {
  font-family: "helveticaNeue", sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #fff;
  font-size: 5.625rem;
}
@media screen and (max-width: 1440px) {
  h1, .h1 {
    font-size: 4.6875rem;
  }
}
@media screen and (max-width: 1024px) {
  h1, .h1 {
    font-size: 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  h1, .h1 {
    font-size: 3.125rem;
  }
}
@media screen and (max-width: 425px) {
  h1, .h1 {
    font-size: 2.8125rem;
  }
}
@media screen and (max-width: 375px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 320px) {
  h1, .h1 {
    font-size: 2.3125rem;
  }
}
h1.smaller, .h1.smaller {
  font-size: 4.0625rem;
}
@media screen and (max-width: 1440px) {
  h1.smaller, .h1.smaller {
    font-size: 3.125rem;
  }
}
@media screen and (max-width: 1024px) {
  h1.smaller, .h1.smaller {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 768px) {
  h1.smaller, .h1.smaller {
    font-size: 2.1875rem;
  }
}
@media screen and (max-width: 425px) {
  h1.smaller, .h1.smaller {
    font-size: 1.875rem;
  }
}

h2, .h2 {
  font-family: "helveticaNeue", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #343b48;
  font-size: 2.625rem;
}
@media screen and (max-width: 1024px) {
  h2, .h2 {
    font-size: 2.3125rem;
  }
}
@media screen and (max-width: 768px) {
  h2, .h2 {
    font-size: 2.0625rem;
  }
}
@media screen and (max-width: 425px) {
  h2, .h2 {
    font-size: 1.9375rem;
  }
}
@media screen and (max-width: 375px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 320px) {
  h2, .h2 {
    font-size: 1.8125rem;
  }
}

h3, .h3 {
  font-family: "helveticaNeue", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #343b48;
  font-size: 2.1875rem;
}
@media screen and (max-width: 1440px) {
  h3, .h3 {
    font-size: 2.0625rem;
  }
}
@media screen and (max-width: 1024px) {
  h3, .h3 {
    font-size: 1.9375rem;
  }
}
@media screen and (max-width: 768px) {
  h3, .h3 {
    font-size: 1.8125rem;
  }
}
@media screen and (max-width: 425px) {
  h3, .h3 {
    font-size: 1.6875rem;
  }
}
@media screen and (max-width: 375px) {
  h3, .h3 {
    font-size: 1.625rem;
  }
}
@media screen and (max-width: 320px) {
  h3, .h3 {
    font-size: 1.5625rem;
  }
}

h4, .h4 {
  font-family: "helveticaNeue", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #343b48;
  font-size: 1.5625rem;
}
@media screen and (max-width: 1024px) {
  h4, .h4 {
    font-size: 1.4375rem;
  }
}
@media screen and (max-width: 768px) {
  h4, .h4 {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 425px) {
  h4, .h4 {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 375px) {
  h4, .h4 {
    font-size: 1.1875rem;
  }
}
@media screen and (max-width: 320px) {
  h4, .h4 {
    font-size: 1.125rem;
  }
}

h5, .h5 {
  font-family: "gtWalsheim", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  color: #343b48;
  font-size: 1.25rem;
}

h6, .h6 {
  font-family: "gtWalsheim", sans-serif;
  font-weight: 700;
  line-height: 1;
  color: #f66f62;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75rem;
}

p {
  font-family: "gtWalsheim", sans-serif;
  line-height: 1.75;
  color: #6e7178;
  font-size: 1.125rem;
}
@media screen and (max-width: 425px) {
  p {
    font-size: 1rem;
  }
}

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.link {
  position: relative;
  padding-left: 20px;
  font: 1rem/1 "gtWalsheim", sans-serif;
  color: #343b48;
  transition: color 300ms;
}
.link:hover {
  color: #f66f62;
}
.link:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 6px;
  border-left-color: #f66f62;
}
.link.white {
  color: #fff;
}
.link.white:hover {
  color: #f66f62;
}

.btn {
  position: relative;
  padding: 20px 40px 21px;
  font: 0.9375rem/1 "gtWalsheim", sans-serif;
  color: #fff;
  z-index: 1;
  transition: color 300ms;
}
.btn:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f66f62;
  border-radius: 40px;
  z-index: -1;
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1), background 300ms;
}
.btn:hover:before {
  transform: scale(0.95);
}
.btn.white {
  color: #343b48;
}
.btn.white:before {
  background: #fff;
}

.stripe-loading-indicator {
  opacity: 0;
  visibility: hidden;
}

.dynamic {
  /* ----- Image - With Caption ----- */
}
.dynamic h2, .dynamic h3, .dynamic p, .dynamic a, .dynamic ul, .dynamic ol, .dynamic table, .dynamic hr, .dynamic img {
  margin: 0;
}
.dynamic > :first-child {
  margin-top: 0 !important;
}
.dynamic > :last-child {
  margin-bottom: 0 !important;
}
.dynamic h2 {
  margin: 70px 0 30px;
  font-family: "helveticaNeue", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  color: #343b48;
  font-size: 2.1875rem;
}
@media screen and (max-width: 1024px) {
  .dynamic h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 425px) {
  .dynamic h2 {
    font-size: 1.75rem;
  }
}
@media screen and (max-width: 375px) {
  .dynamic h2 {
    font-size: 1.6875rem;
  }
}
@media screen and (max-width: 320px) {
  .dynamic h2 {
    font-size: 1.625rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h2 {
    margin: 50px 0 25px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h2 {
    margin: 40px 0 20px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic h2 {
    margin: 30px 0 15px;
  }
}
.dynamic h3 {
  margin: 40px 0 20px;
  font-family: "helveticaNeue", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  color: #343b48;
  font-size: 1.625rem;
}
@media screen and (max-width: 1024px) {
  .dynamic h3 {
    font-size: 1.5625rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h3 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 425px) {
  .dynamic h3 {
    font-size: 1.4375rem;
  }
}
@media screen and (max-width: 375px) {
  .dynamic h3 {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h3 {
    margin: 30px 0 20px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h3 {
    margin: 25px 0 15px;
  }
}
.dynamic h4 {
  margin: 30px 0 15px;
  font-family: "helveticaNeue", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  color: #343b48;
  font-size: 1.375rem;
}
@media screen and (max-width: 1024px) {
  .dynamic h4 {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h4 {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 425px) {
  .dynamic h4 {
    font-size: 1.1875rem;
  }
}
@media screen and (max-width: 375px) {
  .dynamic h4 {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic h4 {
    margin: 25px 0 10px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic h4 {
    margin: 20px 0 10px;
  }
}
.dynamic p {
  margin: 15px 0;
  line-height: 1.5;
  font-family: "gtWalsheim", sans-serif;
  color: #6e7178;
  font-size: 1.125rem;
}
@media screen and (max-width: 768px) {
  .dynamic p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .dynamic p {
    margin: 10px 0;
  }
}
.dynamic strong {
  color: #343b48;
  font-style: normal;
  font-weight: 700;
}
.dynamic em {
  font-style: italic;
  font-weight: normal;
}
.dynamic pre {
  margin: 10px 0;
  font-family: "gtWalsheim", sans-serif;
  line-height: 1.5;
  color: #6e7178;
  white-space: normal;
  font-size: 0.9375rem;
}
@media screen and (max-width: 425px) {
  .dynamic pre {
    font-size: 0.875rem;
  }
}
.dynamic blockquote {
  position: relative;
  padding: 0 0 0 40px;
  margin: 30px 0;
  font-family: "gtWalsheim", sans-serif;
  font-weight: 500;
  line-height: 1.4;
  color: #6e7178;
  font-size: 1.125rem;
}
@media screen and (max-width: 768px) {
  .dynamic blockquote {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1024px) {
  .dynamic blockquote {
    margin: 20px 0;
    padding: 0 0 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic blockquote {
    margin: 15px 0;
    padding: 0 0 0 25px;
  }
}
.dynamic blockquote p {
  font-family: "gtWalsheim", sans-serif;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 1.125rem;
}
@media screen and (max-width: 768px) {
  .dynamic blockquote p {
    font-size: 1rem;
  }
}
.dynamic blockquote:after {
  content: "";
  position: absolute;
  width: 12px;
  top: 5px;
  bottom: 0;
  left: 0;
  background: #f1efed;
}
.dynamic a:not(.link):not(.btn):not(.btnRemoveArtWork) {
  display: inline;
  font: 1.125rem/1.5 "gtWalsheim", sans-serif;
  color: #343b48;
  box-shadow: inset 0px -1px 0 #343b48;
  transition: all 300ms;
}
@media screen and (max-width: 768px) {
  .dynamic a:not(.link):not(.btn):not(.btnRemoveArtWork) {
    font-size: 1rem;
  }
}
.dynamic a:not(.link):not(.btn):not(.btnRemoveArtWork):hover {
  box-shadow: inset 0px -1px 0 #f66f62;
  color: #f66f62;
}
.dynamic .btn {
  margin: 10px 0;
}
.dynamic ol {
  margin: 20px 0;
  list-style-position: inside;
  list-style-type: decimal;
}
@media screen and (max-width: 768px) {
  .dynamic ol {
    margin: 10px 0;
  }
}
.dynamic ol ol {
  margin: 20px 0 0 25px;
}
@media screen and (max-width: 768px) {
  .dynamic ol ol {
    margin: 10px 0 0 10px;
  }
}
.dynamic ol li {
  padding: 15px 0;
  line-height: 1.75;
  font-family: "gtWalsheim", sans-serif;
  color: #6e7178;
  font-size: 1.125rem;
  border-bottom: 1px solid #d6d8da;
}
@media screen and (max-width: 768px) {
  .dynamic ol li {
    font-size: 1rem;
  }
}
.dynamic ol li:first-child {
  padding-top: 0;
}
.dynamic ol li p {
  display: inline;
  color: #6e7178;
}
.dynamic ul {
  margin: 20px 0 20px 20px;
  list-style-position: outside;
  list-style-type: disc;
}
@media screen and (max-width: 768px) {
  .dynamic ul {
    margin: 10px 0 10px 20px;
  }
}
.dynamic ul ul {
  margin: 20px 0 0 25px;
}
@media screen and (max-width: 768px) {
  .dynamic ul ul {
    margin: 10px 0 0 10px;
  }
}
.dynamic ul li {
  margin-bottom: 10px;
  line-height: 1.75;
  font-family: "gtWalsheim", sans-serif;
  color: #f66f62;
  font-size: 1.125rem;
}
@media screen and (max-width: 768px) {
  .dynamic ul li {
    font-size: 1rem;
  }
}
.dynamic ul li p {
  color: #6e7178;
  margin: 0;
}
.dynamic > ul > li:last-child {
  margin-bottom: 0;
}
.dynamic .tableWrapper {
  width: 100%;
  overflow-x: auto;
  margin: 30px 0 50px;
}
@media screen and (max-width: 1024px) {
  .dynamic .tableWrapper {
    margin: 25px 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic .tableWrapper {
    margin: 20px 0 30px;
  }
}
@media screen and (max-width: 500px) {
  .dynamic .tableWrapper {
    margin: 15px 0 25px;
  }
}
.dynamic table {
  width: auto;
  white-space: nowrap;
  overflow-x: hidden;
  table-layout: fixed;
}
.dynamic table thead tr th, .dynamic table thead tr td {
  border: 1px solid #d6d8da;
  padding: 10px 30px;
  color: #fff;
  background: #343b48;
  white-space: normal;
  text-align: left;
}
.dynamic table thead tr th, .dynamic table thead tr th p, .dynamic table thead tr th h4, .dynamic table thead tr th h3, .dynamic table thead tr th h2, .dynamic table thead tr td, .dynamic table thead tr td p, .dynamic table thead tr td h4, .dynamic table thead tr td h3, .dynamic table thead tr td h2 {
  font: 1.125rem/1.5 "gtWalsheim", sans-serif;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  .dynamic table thead tr th, .dynamic table thead tr td {
    font-size: 1rem;
  }
}
.dynamic table tbody tr.style1 td {
  background: #f1efed;
}
.dynamic table tbody tr td, .dynamic table tbody tr th {
  padding: 10px 30px;
  margin: 0;
  font: 1.125rem/1.5 "gtWalsheim", sans-serif;
  font-weight: normal;
  color: #343b48;
  white-space: normal;
  border: 1px solid #d6d8da;
  vertical-align: middle !important;
  background: #fff;
}
@media screen and (max-width: 768px) {
  .dynamic table tbody tr td, .dynamic table tbody tr th {
    font-size: 1rem;
  }
}
.dynamic table tbody tr td > :first-child, .dynamic table tbody tr th > :first-child {
  margin-top: 0 !important;
}
.dynamic table tbody tr td > :last-child, .dynamic table tbody tr th > :last-child {
  margin-bottom: 0 !important;
}
.dynamic table tbody tr td p, .dynamic table tbody tr th p {
  font-size: 1.125rem;
  color: #343b48;
}
@media screen and (max-width: 768px) {
  .dynamic table tbody tr td p, .dynamic table tbody tr th p {
    font-size: 1rem;
  }
}
.dynamic table tbody tr:only-child td:only-child {
  padding: 40px;
  border: 0;
  background: #f1efed;
}
@media screen and (max-width: 1024px) {
  .dynamic table tbody tr:only-child td:only-child {
    padding: 30px;
  }
}
@media screen and (max-width: 768px) {
  .dynamic table tbody tr:only-child td:only-child {
    padding: 25px;
  }
}
.dynamic img {
  position: relative;
  max-width: 100%;
}
.dynamic img.fr-dib {
  /* block */
  display: block;
  margin: 30px auto;
  float: none;
  vertical-align: top;
}
.dynamic img.fr-dib.fr-fil {
  margin-left: 0;
}
.dynamic img.fr-dib.fr-fir {
  margin-right: 0;
}
.dynamic img.fr-dii {
  /* Inline */
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin: 10px 20px;
  max-width: calc(100% - 40px);
}
.dynamic img.fr-dii.fr-fil {
  float: left;
  margin: 10px 20px 10px 0;
  max-width: calc(100% - 20px);
}
.dynamic img.fr-dii.fr-fir {
  float: right;
  margin: 10px 0 10px 20px;
  max-width: calc(100% - 20px);
}
.dynamic .fr-img-caption img {
  width: 100%;
}
.dynamic .fr-img-caption.fr-dib {
  /* block */
  display: block;
  margin-left: auto;
  margin-right: auto;
  float: none;
  vertical-align: middle;
  text-align: center;
}
.dynamic .fr-img-caption.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}
.dynamic .fr-img-caption.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}
.dynamic .fr-img-caption.fr-dib .fr-img-wrap .fr-inner {
  display: block;
  margin: 10px 0;
  font-family: "helveticaNeue", sans-serif;
  line-height: 1.5;
  color: #6e7178;
  white-space: normal;
  font-size: 0.8125rem;
}
.dynamic .fr-img-caption.fr-dii {
  /* Inline */
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin: 10px 20px;
  max-width: calc(100% - 40px);
  text-align: center;
}
.dynamic .fr-img-caption.fr-dii.fr-fil {
  float: left;
  margin-left: 0;
  text-align: left;
  max-width: calc(100% - 20px);
}
.dynamic .fr-img-caption.fr-dii.fr-fir {
  float: right;
  margin-right: 0;
  text-align: right;
  max-width: calc(100% - 20px);
}

.p_rubricsList .dynamic h2 {
  font-size: 1.625rem;
  margin: 25px 0 15px;
}
@media screen and (max-width: 1024px) {
  .p_rubricsList .dynamic h2 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .p_rubricsList .dynamic h2 {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 425px) {
  .p_rubricsList .dynamic h2 {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 375px) {
  .p_rubricsList .dynamic h2 {
    font-size: 1.25rem;
  }
}
.p_rubricsList .dynamic h3 {
  font-size: 1.375rem;
  margin: 20px 0 15px;
}
@media screen and (max-width: 1024px) {
  .p_rubricsList .dynamic h3 {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 768px) {
  .p_rubricsList .dynamic h3 {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 425px) {
  .p_rubricsList .dynamic h3 {
    font-size: 1.1875rem;
  }
}
@media screen and (max-width: 375px) {
  .p_rubricsList .dynamic h3 {
    font-size: 1.125rem;
  }
}
.p_rubricsList .dynamic h4 {
  font-size: 1.125rem;
  margin: 15px 0;
}
@media screen and (max-width: 768px) {
  .p_rubricsList .dynamic h4 {
    font-size: 1rem;
  }
}
.p_rubricsList .dynamic p {
  margin: 10px 0;
}

.artwork {
  margin-bottom: 40px;
}

.hamburger {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 16px;
  cursor: pointer;
  transform: translate(-50%, -50%);
}
.hamburger span {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: #f66f62;
  opacity: 1;
  left: 0%;
  transform: rotate(0deg);
  transition: 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger span:nth-child(1) {
  top: 0px;
}
.hamburger span:nth-child(2), .hamburger span:nth-child(3) {
  top: 7px;
}
.hamburger span:nth-child(4) {
  top: 14px;
}

.showOverlayMenu .hamburger span:nth-child(1) {
  width: 0%;
  top: 16px;
  left: 50%;
  opacity: 0;
}
.showOverlayMenu .hamburger span:nth-child(2) {
  transform: rotate(45deg);
  width: 100%;
}
.showOverlayMenu .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  width: 100%;
}
.showOverlayMenu .hamburger span:nth-child(4) {
  width: 0%;
  top: 16px;
  left: 50%;
  opacity: 0;
}
.showOverlayMenu.closingMenu .hamburger span {
  width: 100%;
  opacity: 1;
  left: 0%;
  transform: rotate(0deg);
  transition: 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.showOverlayMenu.closingMenu .hamburger span:nth-child(1) {
  top: 0px;
}
.showOverlayMenu.closingMenu .hamburger span:nth-child(2), .showOverlayMenu.closingMenu .hamburger span:nth-child(3) {
  top: 7px;
}
.showOverlayMenu.closingMenu .hamburger span:nth-child(4) {
  top: 14px;
}

[data-fade] {
  opacity: 0;
  transition: opacity 800ms;
}
[data-fade][data-scrollfire=fire], [data-fade][data-multiscrollfire=fire] {
  opacity: 1;
  transform: none;
}

[data-fadeup] {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 800ms, transform 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
[data-fadeup][data-scrollfire=fire], [data-fadeup][data-multiscrollfire=fire] {
  opacity: 1;
  transform: none;
}

.landing [data-scrollfire], .landing [data-multiscrollfire] {
  transition-delay: 0ms !important;
}

.transition [data-scrollfire], .transition [data-multiscrollfire] {
  transition-delay: 500ms !important;
}

.transition [data-fade], .landing [data-fade] {
  opacity: 0 !important;
}
.transition [data-fadeup], .landing [data-fadeup] {
  opacity: 0 !important;
  transform: translateY(100px) !important;
}

[data-scrollfire], [data-multiscrollfire] {
  transition-delay: 0ms;
}
[data-scrollfire][data-delay1], [data-multiscrollfire][data-delay1] {
  transition-delay: 50ms;
}
[data-scrollfire][data-delay2], [data-multiscrollfire][data-delay2] {
  transition-delay: 150ms;
}
[data-scrollfire][data-delay3], [data-multiscrollfire][data-delay3] {
  transition-delay: 250ms;
}
[data-scrollfire][data-delay4], [data-multiscrollfire][data-delay4] {
  transition-delay: 350ms;
}
[data-scrollfire][data-delay5], [data-multiscrollfire][data-delay5] {
  transition-delay: 450ms;
}
[data-scrollfire][data-delay6], [data-multiscrollfire][data-delay6] {
  transition-delay: 650ms;
}

.floating {
  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.floating:hover {
  animation-play-state: paused;
}

@keyframes floating {
  0%, 0.001% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
}
.landing .whiteScreen {
  bottom: 0%;
}
.landing #landing {
  top: 0%;
  bottom: 0%;
}

.removeLanding .whiteScreen {
  bottom: 100%;
}
.removeLanding #landing {
  top: -100%;
  bottom: 100%;
}

.whiteScreen {
  position: fixed;
  top: -100%;
  right: 0;
  bottom: 100%;
  left: 0;
  background: #fff;
  z-index: 10000;
  transition: bottom 900ms cubic-bezier(0.32, 0, 0.67, 0);
}

#landing {
  position: fixed;
  top: 100%;
  right: 0;
  bottom: 100%;
  left: 0;
  background: #343b48;
  clip-path: inset(0);
  transition: top 1200ms cubic-bezier(0.16, 1, 0.3, 1) 900ms, bottom 1200ms cubic-bezier(0.16, 1, 0.3, 1) 900ms;
  z-index: 9999;
}
#landing img {
  position: fixed;
  width: 75px;
  height: 75px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#page-transition {
  position: fixed;
  top: 100%;
  right: 0;
  bottom: -100%;
  left: 0;
  background: #343b48;
  clip-path: inset(0);
  z-index: 9999;
  visibility: hidden;
  pointer-events: none;
}

.transition #page-transition {
  top: 0%;
  bottom: 0%;
  visibility: visible;
  transition: top 500ms cubic-bezier(0.33, 1, 0.68, 1), bottom 500ms cubic-bezier(0.33, 1, 0.68, 1);
}

.end #page-transition {
  top: -100%;
  bottom: 100%;
  visibility: visible;
  transition: top 700ms cubic-bezier(0.33, 1, 0.68, 1), bottom 700ms cubic-bezier(0.33, 1, 0.68, 1);
}

form .title {
  margin-bottom: 30px;
  font-family: "helveticaNeue", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #343b48;
  font-size: 1.625rem;
}
@media screen and (max-width: 1024px) {
  form .title {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 768px) {
  form .title {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 425px) {
  form .title {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 375px) {
  form .title {
    font-size: 1.1875rem;
  }
}
@media screen and (max-width: 320px) {
  form .title {
    font-size: 1.125rem;
  }
}
form .errorMessage {
  margin-top: 5px;
  font-family: "gtWalsheim", sans-serif;
  line-height: 1.5;
  color: #f66f62;
  font-size: 0.9375rem;
}
@media screen and (max-width: 768px) {
  form .errorMessage {
    font-size: 0.875rem;
  }
}
form .note {
  margin-top: 5px;
  font-family: "gtWalsheim", sans-serif;
  line-height: 1.5;
  color: #6e7178;
  font-size: 0.9375rem;
}
@media screen and (max-width: 768px) {
  form .note {
    font-size: 0.875rem;
  }
}
form label {
  display: block;
  margin-bottom: 5px;
  font: 1.0625rem/1.5 "gtWalsheim", sans-serif;
  color: #6e7178;
}
@media screen and (max-width: 768px) {
  form label {
    font-size: 1rem;
  }
}

.textWrapper {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .textWrapper {
    margin-bottom: 20px;
  }
}

.textWrapper-artwork {
  display: flex;
  margin-bottom: 15px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .textWrapper-artwork {
    margin-bottom: 20px;
  }
}

.inputField, .textField, .checkboxesWrapper, .radioWrapper, .selectField, .fileField {
  position: relative;
  margin-bottom: 50px;
}
@media screen and (max-width: 1200px) {
  .inputField, .textField, .checkboxesWrapper, .radioWrapper, .selectField, .fileField {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .inputField, .textField, .checkboxesWrapper, .radioWrapper, .selectField, .fileField {
    margin-bottom: 30px;
  }
}

.inputField input {
  width: 100%;
  padding: 0 0 5px 0;
  font: 1.0625rem/1 "gtWalsheim", sans-serif;
  color: #343b48;
  background: transparent;
  border: none;
  border-bottom: 1px solid #d6d8da;
  transition: border 300ms;
}
@media screen and (max-width: 768px) {
  .inputField input {
    font-size: 1rem;
  }
}
.inputField input:focus {
  border-bottom: 1px solid #343b48;
}
.inputField input.error {
  border-bottom: 1px solid #f66f62;
}
.inputField ::-webkit-input-placeholder {
  color: rgba(52, 59, 72, 0.5);
  font-weight: normal;
}
.inputField ::-moz-placeholder {
  color: rgba(52, 59, 72, 0.5);
  font-weight: normal;
}
.inputField :-ms-input-placeholder {
  color: rgba(52, 59, 72, 0.5);
  font-weight: normal;
}
.inputField :-moz-placeholder {
  color: rgba(52, 59, 72, 0.5);
  font-weight: normal;
}
.inputField.style2 input {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.inputField.style2 input:focus {
  border-bottom: 1px solid #fff;
}
.inputField.style2 ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.inputField.style2 ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.inputField.style2 :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.inputField.style2 :-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.textField textarea {
  width: 100%;
  padding: 0 0 5px 0;
  height: 32px;
  min-height: 32px;
  font: 1.0625rem/1.5 "gtWalsheim", sans-serif;
  color: #343b48;
  border: none;
  border-bottom: 1px solid #d6d8da;
  box-shadow: none;
  outline: none;
  resize: none;
  overflow: hidden;
  transition: border 300ms;
}
@media screen and (max-width: 768px) {
  .textField textarea {
    font-size: 1rem;
  }
}
.textField textarea:focus {
  border-bottom: 1px solid #343b48;
}
.textField textarea.error {
  border-bottom: 1px solid #f66f62;
}
.textField ::-webkit-input-placeholder {
  color: rgba(52, 59, 72, 0.5);
}
.textField ::-moz-placeholder {
  color: rgba(52, 59, 72, 0.5);
}
.textField :-ms-input-placeholder {
  color: rgba(52, 59, 72, 0.5);
}
.textField :-moz-placeholder {
  color: rgba(52, 59, 72, 0.5);
}

.checkboxField label {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  margin: 0 30px 20px 0;
}
.checkboxField label.noMargin {
  margin: 0 30px 0px 0;
}
.checkboxField label a {
  display: inline;
  font: inherit;
  color: #343b48;
  box-shadow: inset 0px -1px 0 #343b48;
  transition: all 300ms;
}
@media screen and (max-width: 768px) {
  .checkboxField label a {
    font-size: 1rem;
  }
}
.checkboxField label a:hover {
  box-shadow: inset 0px -1px 0 #f66f62;
  color: #f66f62;
}
.checkboxField label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkboxField label span {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 4px;
  left: 0;
  border-radius: 3px;
  border: 1px solid #343b48;
  transition: background 225ms, border-color 225ms;
}
@media screen and (max-width: 768px) {
  .checkboxField label span {
    top: 3px;
  }
}
.checkboxField label span:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 10px;
  height: 10px;
  top: 50%;
  background: url("../images/content/icons/crochet.png") no-repeat center/contain;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 225ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.checkboxField label input:checked ~ span {
  opacity: 1;
  background: #f66f62;
  border-color: #f66f62;
}
.checkboxField label input:checked ~ span:before {
  transform: translate(-50%, -50%) scale(1);
}

.radioField label {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  margin: 0 30px 20px 0;
}
.radioField label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radioField label span {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 4px;
  left: 0;
  border: 1px solid #343b48;
  border-radius: 50%;
  transition: background 225ms, border-color 225ms;
}
@media screen and (max-width: 768px) {
  .radioField label span {
    top: 3px;
  }
}
.radioField label span:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 10px;
  height: 10px;
  top: 50%;
  background: #f66f62;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 225ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.radioField label input:checked ~ span {
  opacity: 1;
}
.radioField label input:checked ~ span:before {
  transform: translate(-50%, -50%) scale(1);
}

.radioWrapper .radioField {
  display: inline-block;
  height: 30px;
}
.radioWrapper .radioField label {
  margin-bottom: 0;
}

.w-50 {
  width: 50%;
  padding: 10px 30px 0px 0px;
}

.flex-form {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 3;
}

.fileField {
  max-width: 400px;
}
.fileField .uploadBtnWrapper {
  position: relative;
  width: 100%;
  display: inline-block;
}
.fileField .uploadBtnWrapper input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.fileField .uploadBtnWrapper .btnUpload {
  display: block;
  position: relative;
  width: 100%;
  padding: 20px 80px 20px 25px;
  font: 1.125rem/1.4 "gtWalsheim", sans-serif;
  color: #343b48;
  border: 1px solid rgba(52, 59, 72, 0.2);
  cursor: pointer;
  transition: color 300ms, background 300ms;
}
@media screen and (max-width: 768px) {
  .fileField .uploadBtnWrapper .btnUpload {
    padding: 20px 70px 20px 20px;
  }
}
.fileField .uploadBtnWrapper .btnUpload svg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 20px;
  fill: #f66f62;
  transform: translate(-50%, -50%);
  transition: fill 300ms;
}
.fileField .uploadBtnWrapper:hover .btnUpload {
  color: #f66f62;
}

.custom-select {
  position: relative;
  z-index: 20;
}
.custom-select select {
  display: none !important;
}
.custom-select.custom-select-active {
  transition: box-shadow 300ms;
}
.custom-select.custom-select-active .custom-select-items {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transition: opacity 300ms;
}
.custom-select .custom-select-selected {
  position: relative;
  z-index: 20;
}
.custom-select .custom-select-items {
  position: absolute;
  max-height: 200px;
  overflow: auto;
  top: 100%;
  right: 0;
  left: 0;
  opacity: 0;
  z-index: 19;
  visibility: hidden;
  pointer-events: none;
  overflow-x: hidden;
  scrollbar-width: none;
  transition: opacity 300ms, visibility 0ms ease 300ms;
}
.custom-select .custom-select-items::-webkit-scrollbar {
  display: none;
}
.custom-select .custom-select-items .custom-select-item {
  cursor: pointer;
}

.custom-select.style1 {
  max-width: 300px;
}
.custom-select.style1 .custom-select-selected {
  padding: 15px 40px 17px 30px;
  font: 0.9375rem/1 "gtWalsheim", sans-serif;
  color: #343b48;
  background: #fff;
  border: 1px solid #d6d8da;
  cursor: pointer;
}
.custom-select.style1 .custom-select-selected:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-top-color: #f66f62;
}
.custom-select.style1.custom-select-active .custom-select-selected:after {
  transform: translateY(-50%) rotate(-180deg);
}
.custom-select.style1.custom-select-active .custom-select-items {
  transform: none;
  transition: opacity 300ms, transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.custom-select.style1 .custom-select-items {
  background: #fff;
  border: 1px solid #d6d8da;
  border-top: none;
  transform: translateY(-30px);
  transition: opacity 300ms, transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1), visibility 0ms ease 300ms;
}
.custom-select.style1 .custom-select-items .custom-select-item {
  padding: 15px 30px 17px 30px;
  font: 0.9375rem/1 "gtWalsheim", sans-serif;
  color: #343b48;
  transition: background 300ms, color 300ms;
}
.custom-select.style1 .custom-select-items .custom-select-item:hover {
  background: #f1efed;
}
.custom-select.style1 .custom-select-items .custom-select-item.custom-select-same {
  background: #f1efed;
}

.errorMessageDZ {
  position: absolute;
  bottom: -50px;
}

#nbProject-error {
  margin: 30px 0;
}

.p_blackScreen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(37, 37, 37, 0.7);
  visibility: hidden;
  z-index: 10;
  transition: opacity 800ms;
}

.p_filters {
  width: calc(100% + 100px);
  max-width: calc(100% + 100px);
  height: 25px;
  font-size: 0;
  margin-right: -50px;
  margin-left: -50px;
}
@media screen and (max-width: 768px) {
  .p_filters {
    width: calc(100% + 60px);
    max-width: calc(100% + 60px);
    margin-right: -30px;
    margin-left: -30px;
  }
}
@media screen and (max-width: 600px) {
  .p_filters {
    width: calc(100% + 50px);
    max-width: calc(100% + 50px);
    margin-right: -25px;
    margin-left: -25px;
  }
}
.p_filters .dragAndSlide {
  padding-right: 50px;
  padding-left: 50px;
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  transform: scale(1);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
@media screen and (max-width: 768px) {
  .p_filters .dragAndSlide {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 600px) {
  .p_filters .dragAndSlide {
    padding-right: 25px;
    padding-left: 25px;
  }
}
.p_filters .dragAndSlide::-webkit-scrollbar {
  width: 0 !important;
}
.p_filters .dragAndSlide a {
  position: relative;
  font: 0.75rem/1 "gtWalsheim", sans-serif;
  font-weight: 700;
  padding: 0 0 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #6e7178;
  transition: color 300ms;
  overflow: hidden;
}
.p_filters .dragAndSlide a:not(:last-child) {
  margin-right: 50px;
}
@media screen and (max-width: 1200px) {
  .p_filters .dragAndSlide a:not(:last-child) {
    margin-right: 35px;
  }
}
.p_filters .dragAndSlide a:after {
  content: "";
  position: absolute;
  height: 4px;
  bottom: -4px;
  left: 0;
  right: 0;
  background: #f66f62;
  transition: bottom 300ms;
}
.p_filters .dragAndSlide a:hover:after {
  bottom: 0px;
}
.p_filters .dragAndSlide a.active:after {
  bottom: 0px;
}

.p_prevNext {
  display: flex;
  flex-flow: row wrap;
  padding: 20px 0;
  margin-top: 90px;
  border-top: 1px solid #d6d8da;
  border-bottom: 1px solid #d6d8da;
}
@media screen and (max-width: 768px) {
  .p_prevNext {
    margin-top: 70px;
  }
}
@media screen and (max-width: 500px) {
  .p_prevNext {
    margin-top: 50px;
  }
}
.p_prevNext div {
  width: 50%;
}
.p_prevNext a {
  font-family: "gtWalsheim", sans-serif;
  line-height: 1;
  color: #343b48;
  transition: color 300ms;
  font-size: 1.375rem;
}
@media screen and (max-width: 768px) {
  .p_prevNext a {
    font-size: 1.25rem;
  }
}
.p_prevNext a:hover {
  color: #f66f62;
}
.p_prevNext svg {
  width: 16px;
  height: 16px;
  stroke: #f66f62;
}
.p_prevNext .prev svg {
  margin-right: 10px;
  transform: rotate(90deg);
}
.p_prevNext .next {
  text-align: right;
}
.p_prevNext .next svg {
  margin-left: 10px;
  transform: rotate(-90deg);
}
.p_prevNext .hidden {
  visibility: hidden;
}

.p_rubricsList {
  list-style-type: none;
}
.p_rubricsList > li {
  position: relative;
  overflow: hidden;
  transition: border 300ms;
}
.p_rubricsList > li:not(:last-child) {
  margin-bottom: 15px;
}
.p_rubricsList > li:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f1efed;
}
.p_rubricsList > li .clickable {
  display: block;
  position: relative;
  width: 100%;
  padding: 40px 20px;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .p_rubricsList > li .clickable {
    padding: 30px 20px;
  }
}
@media screen and (max-width: 500px) {
  .p_rubricsList > li .clickable {
    padding: 25px 20px;
  }
}
@media screen and (max-width: 320px) {
  .p_rubricsList > li .clickable {
    padding: 25px 15px;
  }
}
.p_rubricsList > li .clickable:hover .flex div p {
  color: #f66f62;
}
.p_rubricsList > li .clickable:hover .icon:before {
  transform: scale(0.9);
}
.p_rubricsList > li .clickable .w800 {
  position: relative;
}
.p_rubricsList > li .clickable .w800 .flex {
  display: flex;
  align-items: center;
  padding-right: 70px;
}
@media screen and (max-width: 1024px) {
  .p_rubricsList > li .clickable .w800 .flex {
    padding-right: 60px;
  }
}
@media screen and (max-width: 600px) {
  .p_rubricsList > li .clickable .w800 .flex {
    min-height: 100%;
    padding-right: 55px;
  }
}
.p_rubricsList > li .clickable .w800 .flex div {
  width: 100%;
}
.p_rubricsList > li .clickable p {
  font-family: "gtWalsheim", sans-serif;
  line-height: 1.5;
  color: #343b48;
  transition: color 300ms;
  font-size: 1.375rem;
}
@media screen and (max-width: 768px) {
  .p_rubricsList > li .clickable p {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 425px) {
  .p_rubricsList > li .clickable p {
    font-size: 1.125rem;
  }
}
.p_rubricsList > li .clickable .icon {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: transform 300ms;
}
@media screen and (max-width: 1024px) {
  .p_rubricsList > li .clickable .icon {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 600px) {
  .p_rubricsList > li .clickable .icon {
    width: 35px;
    height: 35px;
  }
}
.p_rubricsList > li .clickable .icon:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  border-radius: 50%;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.p_rubricsList > li .clickable .icon svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  stroke: #f66f62;
  transform: translate(-50%, -50%);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 600px) {
  .p_rubricsList > li .clickable .icon svg {
    width: 14px;
    height: 14px;
  }
}
.p_rubricsList > li.open .clickable p {
  color: #f66f62;
}
.p_rubricsList > li.open .clickable .icon svg {
  transform: translate(-50%, -50%) rotate(-180deg);
}
.p_rubricsList > li.open > .drawer {
  opacity: 1;
  overflow: hidden;
  transform: translateY(0);
  transition: transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms, opacity 600ms linear 300ms;
}
.p_rubricsList > li .drawer {
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 300ms, transform 300ms;
  z-index: 1;
}
.p_rubricsList > li .drawer .w800 {
  position: relative;
  padding-bottom: 83px;
}
@media screen and (max-width: 1024px) {
  .p_rubricsList > li .drawer .w800 {
    padding-bottom: 73px;
  }
}
@media screen and (max-width: 768px) {
  .p_rubricsList > li .drawer .w800 {
    padding-bottom: 63px;
  }
}
@media screen and (max-width: 600px) {
  .p_rubricsList > li .drawer .w800 {
    padding-bottom: 57px;
  }
}
.p_rubricsList > li .drawer .p_gallery, .p_rubricsList > li .drawer .documents.small {
  margin-top: 50px;
}
@media screen and (max-width: 1200px) {
  .p_rubricsList > li .drawer .p_gallery, .p_rubricsList > li .drawer .documents.small {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .p_rubricsList > li .drawer .p_gallery, .p_rubricsList > li .drawer .documents.small {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .p_rubricsList > li .drawer .p_gallery, .p_rubricsList > li .drawer .documents.small {
    margin-top: 20px;
  }
}
.p_rubricsList > li .drawer .btnShare {
  position: absolute;
  padding: 15px 25px;
  right: 0;
  bottom: -2px;
  font: 0.75rem/1 "gtWalsheim", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #f66f62;
  z-index: 1;
}
.p_rubricsList > li .drawer .btnShare:hover:before {
  transform: scale(0.9);
}
.p_rubricsList > li .drawer .btnShare:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: -1;
}
.p_rubricsList > li .drawer .btnShare svg {
  position: relative;
  top: -1px;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  fill: #f66f62;
  vertical-align: middle;
}
.p_rubricsList > li .documents a {
  position: relative;
  width: 100%;
  padding: 30px 50px 30px 0;
  border-bottom: 1px solid #d6d8da;
}
@media screen and (max-width: 600px) {
  .p_rubricsList > li .documents a {
    padding: 20px 50px 20px 0;
  }
}
.p_rubricsList > li .documents a:hover .flex p {
  color: #f66f62;
}
.p_rubricsList > li .documents a .flex {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .p_rubricsList > li .documents a .flex {
    min-height: 100%;
  }
}
.p_rubricsList > li .documents a .flex div {
  width: 100%;
}
.p_rubricsList > li .documents a .flex p {
  font-family: "gtWalsheim", sans-serif;
  line-height: 1.5;
  color: #343b48;
  transition: color 300ms;
  font-size: 1.375rem;
}
@media screen and (max-width: 768px) {
  .p_rubricsList > li .documents a .flex p {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 425px) {
  .p_rubricsList > li .documents a .flex p {
    font-size: 1.125rem;
  }
}
.p_rubricsList > li .documents a svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 20px;
  fill: #f66f62;
  transform: translateY(-50%);
}
.p_rubricsList > li .documents.plus a .flex {
  padding-left: 100px;
}
@media screen and (max-width: 600px) {
  .p_rubricsList > li .documents.plus a .flex {
    padding-left: 0;
  }
}
.p_rubricsList > li .documents.plus a h6 {
  margin-bottom: 5px;
}
.p_rubricsList > li .documents.plus a picture {
  position: absolute;
  top: 50%;
  left: 0;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
  transform: translateY(-50%);
}
@media screen and (max-width: 600px) {
  .p_rubricsList > li .documents.plus a picture {
    display: none;
  }
}
.p_rubricsList > li .documents.small a {
  max-width: 400px;
  padding: 20px 50px 20px 0;
}
.p_rubricsList.plus > li {
  padding-top: 20px;
}
@media screen and (max-width: 600px) {
  .p_rubricsList.plus > li {
    padding-top: 0;
  }
}
.p_rubricsList.plus > li:before {
  top: 20px;
}
@media screen and (max-width: 600px) {
  .p_rubricsList.plus > li:before {
    top: 0;
  }
}
.p_rubricsList.plus > li .clickable {
  padding-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .p_rubricsList.plus > li .clickable {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.p_rubricsList.plus > li .clickable .flex {
  padding-left: 160px;
  min-height: 50px;
}
@media screen and (max-width: 1024px) {
  .p_rubricsList.plus > li .clickable .flex {
    padding-left: 120px;
  }
}
@media screen and (max-width: 600px) {
  .p_rubricsList.plus > li .clickable .flex {
    padding-left: 0;
  }
}
.p_rubricsList.plus > li .clickable h6 {
  margin-bottom: 5px;
}
.p_rubricsList.plus > li .clickable picture {
  position: absolute;
  top: -60px;
  left: 0;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .p_rubricsList.plus > li .clickable picture {
    width: 100px;
    height: 100px;
    top: -40px;
  }
}
@media screen and (max-width: 600px) {
  .p_rubricsList.plus > li .clickable picture {
    display: none;
  }
}
.p_rubricsList.plus > li .drawer .dynamic {
  margin-top: 20px;
}
@media screen and (max-width: 1024px) {
  .p_rubricsList.plus > li .drawer .dynamic {
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  .p_rubricsList.plus > li .drawer .dynamic {
    margin-top: 0;
  }
}

.p_documentsList {
  list-style-type: none;
}
.p_documentsList > li {
  position: relative;
  background: #f1efed;
  overflow: hidden;
  transition: border 300ms;
}
.p_documentsList > li:not(:last-child) {
  margin-bottom: 15px;
}
.p_documentsList > li a {
  display: block;
  position: relative;
  width: 100%;
  padding: 40px 20px;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .p_documentsList > li a {
    padding: 30px 20px;
  }
}
@media screen and (max-width: 500px) {
  .p_documentsList > li a {
    padding: 25px 20px;
  }
}
@media screen and (max-width: 320px) {
  .p_documentsList > li a {
    padding: 25px 15px;
  }
}
.p_documentsList > li a:hover .flex p {
  color: #f66f62;
}
.p_documentsList > li a:hover .icon:before {
  transform: scale(0.9);
}
.p_documentsList > li a .w800 {
  position: relative;
}
.p_documentsList > li a .flex {
  display: flex;
  align-items: center;
  padding-right: 70px;
}
@media screen and (max-width: 600px) {
  .p_documentsList > li a .flex {
    min-height: 100%;
  }
}
.p_documentsList > li a .flex div {
  width: 100%;
}
.p_documentsList > li a .flex p {
  font-family: "gtWalsheim", sans-serif;
  line-height: 1.5;
  color: #343b48;
  transition: color 300ms;
  font-size: 1.375rem;
}
@media screen and (max-width: 768px) {
  .p_documentsList > li a .flex p {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 425px) {
  .p_documentsList > li a .flex p {
    font-size: 1.125rem;
  }
}
.p_documentsList > li a .icon {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: transform 300ms;
}
@media screen and (max-width: 1024px) {
  .p_documentsList > li a .icon {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 600px) {
  .p_documentsList > li a .icon {
    width: 35px;
    height: 35px;
  }
}
.p_documentsList > li a .icon:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  border-radius: 50%;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.p_documentsList > li a .icon svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  fill: #f66f62;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
  .p_documentsList > li a .icon svg {
    width: 14px;
    height: 14px;
  }
}
.p_documentsList.plus li {
  padding-top: 20px;
  background: transparent;
}
@media screen and (max-width: 600px) {
  .p_documentsList.plus li {
    padding-top: 0;
  }
}
.p_documentsList.plus li:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f1efed;
}
.p_documentsList.plus li:before {
  top: 20px;
}
@media screen and (max-width: 600px) {
  .p_documentsList.plus li:before {
    top: 0;
  }
}
.p_documentsList.plus li a {
  padding-bottom: 35px;
}
@media screen and (max-width: 1024px) {
  .p_documentsList.plus li a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.p_documentsList.plus li a .flex {
  padding-left: 160px;
}
@media screen and (max-width: 1024px) {
  .p_documentsList.plus li a .flex {
    padding-left: 120px;
  }
}
@media screen and (max-width: 600px) {
  .p_documentsList.plus li a .flex {
    padding-left: 0;
  }
}
.p_documentsList.plus li a h6 {
  margin-bottom: 5px;
}
.p_documentsList.plus li a picture {
  position: absolute;
  top: -60px;
  left: 0;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .p_documentsList.plus li a picture {
    width: 100px;
    height: 100px;
    top: -40px;
  }
}
@media screen and (max-width: 600px) {
  .p_documentsList.plus li a picture {
    display: none;
  }
}

.p_gallery a {
  position: relative;
  float: left;
}
.p_gallery a:hover:before, .p_gallery a:hover:after {
  opacity: 1;
}
.p_gallery a:hover picture:after {
  background: rgba(52, 59, 72, 0.7);
}
.p_gallery a picture {
  width: 100%;
}
.p_gallery a picture:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(52, 59, 72, 0);
  transition: background 300ms;
}
.p_gallery .photo1 {
  float: left;
  width: calc(100% - 175px);
  margin-right: 30px;
}
@media screen and (max-width: 1024px) {
  .p_gallery .photo1 {
    margin-right: 20px;
  }
}
@media screen and (max-width: 500px) {
  .p_gallery .photo1 {
    width: 100%;
  }
}
.p_gallery .photo1 picture {
  height: 320px;
}
@media screen and (max-width: 500px) {
  .p_gallery .photo1 picture {
    height: 275px;
  }
}
@media screen and (max-width: 375px) {
  .p_gallery .photo1 picture {
    height: 225px;
  }
}
.p_gallery .photo2, .p_gallery .photo3 {
  width: 145px;
}
@media screen and (max-width: 1024px) {
  .p_gallery .photo2, .p_gallery .photo3 {
    width: 155px;
  }
}
@media screen and (max-width: 500px) {
  .p_gallery .photo2, .p_gallery .photo3 {
    width: calc(50% - 10px);
    margin-top: 20px;
  }
}
.p_gallery .photo2 picture, .p_gallery .photo3 picture {
  height: 145px;
}
@media screen and (max-width: 1024px) {
  .p_gallery .photo2 picture, .p_gallery .photo3 picture {
    height: 150px;
  }
}
@media screen and (max-width: 425px) {
  .p_gallery .photo2 picture, .p_gallery .photo3 picture {
    height: 125px;
  }
}
@media screen and (max-width: 375px) {
  .p_gallery .photo2 picture, .p_gallery .photo3 picture {
    height: 100px;
  }
}
.p_gallery .photo2 {
  margin-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .p_gallery .photo2 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 500px) {
  .p_gallery .photo2 {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.p_gallery .photo3 svg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  stroke: #fff;
  stroke-width: 5px;
  fill: none;
  transform: translate(-50%, -50%);
}
.p_gallery .photo3 picture {
  position: relative;
}
.p_gallery .photo3 picture:after {
  background: rgba(52, 59, 72, 0.5);
}
.p_gallery a:not(.photo1):not(.photo2):not(.photo3) {
  display: none;
}

.p_copyright {
  position: absolute;
  padding: 10px 15px;
  right: 0;
  bottom: 0px;
  font: 0.8125rem/1 "gtWalsheim", sans-serif;
  color: #fff;
  background: rgba(52, 59, 72, 0.7);
  z-index: 1;
}

.b_program {
  position: relative;
  width: 100%;
}
.b_program:hover picture:after {
  background: rgba(52, 59, 72, 0.5);
}
.b_program:hover h4 {
  color: #f66f62;
}
.b_program picture {
  position: relative;
  height: 520px;
}
@media screen and (max-width: 1300px) {
  .b_program picture {
    height: 475px;
  }
}
@media screen and (max-width: 1200px) {
  .b_program picture {
    height: 425px;
  }
}
@media screen and (max-width: 600px) {
  .b_program picture {
    height: 350px;
  }
}
@media screen and (max-width: 500px) {
  .b_program picture {
    height: 400px;
  }
}
@media screen and (max-width: 425px) {
  .b_program picture {
    height: 375px;
  }
}
@media screen and (max-width: 375px) {
  .b_program picture {
    height: 325px;
  }
}
.b_program picture:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(52, 59, 72, 0);
  transition: background 300ms;
}
.b_program .text {
  position: absolute;
  right: 75px;
  bottom: 0;
  left: 0;
  padding: 30px 20px 0;
  background: #fff;
}
@media screen and (max-width: 1300px) {
  .b_program .text {
    right: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .b_program .text {
    right: 40px;
  }
}
@media screen and (max-width: 1100px) {
  .b_program .text {
    right: 25px;
  }
}
@media screen and (max-width: 1024px) {
  .b_program .text {
    padding: 25px 15px 0;
  }
}
.b_program p {
  margin: 5px 0 20px;
  font-family: "gtWalsheim", sans-serif;
  color: #343b48;
  line-height: 1;
  font-size: 0.9375rem;
}
@media screen and (max-width: 1024px) {
  .b_program p {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 768px) {
  .b_program p {
    font-size: 0.8125rem;
  }
}
@media screen and (max-width: 1024px) {
  .b_program p {
    margin-bottom: 15px;
  }
}
.b_program h4 {
  transition: color 300ms;
}

.b_publication {
  display: block;
}
.b_publication:hover h4 {
  opacity: 0.4;
}
.b_publication h6 {
  margin: 30px 0 10px;
}
.b_publication h4 {
  transition: opacity 300ms;
}
.b_publication p {
  margin-top: 5px;
  font-size: 0.9375rem;
}

#overlayMenu {
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: 150;
  transition: top 300ms;
}
#overlayMenu .wrapper {
  position: absolute;
  width: 450px;
  padding-top: 150px;
  top: 0;
  right: -450px;
  bottom: 0;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  z-index: 102;
}
@media screen and (max-width: 700px) {
  #overlayMenu .wrapper {
    width: 100%;
    right: -100%;
    padding-top: 115px;
  }
}
#overlayMenu .wrapper::-webkit-scrollbar {
  display: none;
}
#overlayMenu a.logo {
  position: absolute;
  top: 30px;
  left: 60px;
  font-size: 0;
}
@media screen and (max-width: 768px) {
  #overlayMenu a.logo {
    top: 25px;
    left: 30px;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu a.logo {
    top: 20px;
    left: 25px;
  }
}
#overlayMenu a.logo img {
  width: 210px;
  transition: opacity 300ms;
}
@media screen and (max-width: 1300px) {
  #overlayMenu a.logo img {
    width: 190px;
  }
}
@media screen and (max-width: 1200px) {
  #overlayMenu a.logo img {
    width: 175px;
  }
}
#overlayMenu .groundFloor {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  #overlayMenu .groundFloor {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu .groundFloor {
    margin-bottom: 10px;
  }
}
#overlayMenu .groundFloor > li {
  overflow: hidden;
  border-bottom: 1px solid rgba(241, 239, 237, 0);
  transition: background 300ms, border 300ms, margin 300ms;
}
#overlayMenu .groundFloor > li > a {
  position: relative;
  padding: 15px 60px;
  font: 1.5625rem/1.1 "gtWalsheim", sans-serif;
  font-weight: 500;
  overflow: hidden;
  color: #343b48;
  opacity: 1;
  transition: opacity 300ms, padding 300ms;
}
@media screen and (max-width: 768px) {
  #overlayMenu .groundFloor > li > a {
    padding: 15px 30px;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu .groundFloor > li > a {
    padding: 15px 25px;
  }
}
#overlayMenu .groundFloor > li .btnSubLvl1 {
  position: relative;
  padding-right: 20px;
}
#overlayMenu .groundFloor > li .btnSubLvl1:after {
  content: "";
  position: absolute;
  right: 0;
  top: 28px;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-top-color: #f66f62;
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
@media screen and (max-width: 768px) {
  #overlayMenu .groundFloor > li .btnSubLvl1:after {
    top: 24px;
  }
}
#overlayMenu .groundFloor > li.open .btnSubLvl1 {
  opacity: 1;
}
#overlayMenu .groundFloor > li.open .btnSubLvl1:after {
  transform: translateY(-50%) rotate(-180deg);
}
#overlayMenu .groundFloor > li.open .subLvl1 {
  opacity: 1;
  transform: none;
  transition: opacity 300ms, transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
#overlayMenu .other {
  margin-top: 30px;
}
#overlayMenu .other a {
  position: relative;
  padding: 5px 60px;
  font: 1.125rem/1.1 "gtWalsheim", sans-serif;
  font-weight: 500;
  overflow: hidden;
  color: #343b48;
  opacity: 1;
  transition: opacity 300ms, padding 300ms;
}
@media screen and (max-width: 768px) {
  #overlayMenu .other a {
    padding: 15px 30px;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu .other a {
    padding: 15px 25px;
  }
}
#overlayMenu .subLvl1 {
  display: none;
  opacity: 0;
  overflow: hidden;
  background: #f1efed;
  transform: translateY(30px);
  transition: opacity 300ms, transform 300ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
#overlayMenu .subLvl1 li:first-child a {
  padding-top: 15px;
}
#overlayMenu .subLvl1 li:last-child a {
  padding-bottom: 15px;
}
#overlayMenu .subLvl1 li a {
  padding: 5px 60px;
  font: 1.125rem/1.5 "gtWalsheim", sans-serif;
  color: #343b48;
  transition: color 300ms;
}
@media screen and (max-width: 768px) {
  #overlayMenu .subLvl1 li a {
    padding: 50px 30px;
  }
}
@media screen and (max-width: 600px) {
  #overlayMenu .subLvl1 li a {
    padding: 15px 25px;
  }
}
#overlayMenu .subLvl1 li a:hover {
  color: #f66f62;
}

.showOverlayMenu.openingMenu #overlayMenu .wrapper {
  overflow: hidden;
  transition: right 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.showOverlayMenu.openingMenu #overlayMenu .groundFloor > li:first-child {
  transition-delay: 300ms;
}
.showOverlayMenu.openingMenu #overlayMenu .groundFloor > li:nth-child(2) {
  transition-delay: 350ms;
}
.showOverlayMenu.openingMenu #overlayMenu .groundFloor > li:last-child {
  transition-delay: 400ms;
}
.showOverlayMenu.closingMenu #overlayMenu .p_blackScreen {
  opacity: 0;
}
.showOverlayMenu.closingMenu #overlayMenu .wrapper {
  right: -450px;
  transition: right 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 700px) {
  .showOverlayMenu.closingMenu #overlayMenu .wrapper {
    right: -100%;
  }
}
.showOverlayMenu .btnMenu:before {
  border-color: rgba(52, 59, 72, 0.2);
}
.showOverlayMenu #overlayMenu {
  visibility: visible;
}
.showOverlayMenu #overlayMenu .p_blackScreen {
  opacity: 1;
  visibility: visible;
}
.showOverlayMenu #overlayMenu .wrapper {
  right: 0px;
  overflow-y: auto;
}
@media screen and (max-width: 700px) {
  .showOverlayMenu #overlayMenu .wrapper {
    right: 0%;
  }
}

#overlayDropdown {
  position: absolute;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: 9;
}
#overlayDropdown .wrapper {
  position: absolute;
  width: 1080px;
  top: 0;
  right: 0;
  bottom: 100%;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  z-index: 10;
}
@media screen and (max-width: 1400px) {
  #overlayDropdown .wrapper {
    width: 1010px;
  }
}
#overlayDropdown .wrapper::-webkit-scrollbar {
  display: none;
}
#overlayDropdown .wrapper ul {
  padding: 200px 150px 0 65px;
}
@media screen and (max-width: 1400px) {
  #overlayDropdown .wrapper ul {
    padding-left: 60px;
  }
}
#overlayDropdown .wrapper ul li {
  padding: 15px 0;
  opacity: 0;
  transform: translateY(100px);
}
#overlayDropdown .wrapper ul li:first-child {
  padding-top: 0;
}
#overlayDropdown .wrapper ul li:last-child {
  padding-bottom: 0;
}
#overlayDropdown .wrapper ul li a {
  font: 2.25rem/1 "gtWalsheim", sans-serif;
  color: #343b48;
  transition: color 300ms;
}
#overlayDropdown .wrapper ul li a:hover {
  color: #f66f62;
}

.showOverlayDropdown.openingDropdown #overlayDropdown .wrapper {
  transition: bottom 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.showOverlayDropdown.closingDropdown #overlayDropdown .p_blackScreen {
  opacity: 0;
}
.showOverlayDropdown.closingDropdown #overlayDropdown .wrapper {
  bottom: 100%;
  transition: bottom 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.showOverlayDropdown.closingDropdown #overlayDropdown .wrapper ul li {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 300ms, transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1) !important;
}
.showOverlayDropdown.closingDropdown header.style2 .headerLinks {
  color: #fff;
  transition-delay: 300ms;
}
.showOverlayDropdown.closingDropdown header.style2 .btn {
  color: #343b48;
  transition-delay: 300ms;
}
.showOverlayDropdown.closingDropdown header.style2 .btn:before {
  background: #fff;
  transition-delay: 300ms;
}
.showOverlayDropdown.closingDropdown .btnDropdown:after {
  transform: rotate(0deg);
}
.showOverlayDropdown #overlayDropdown {
  visibility: visible;
}
.showOverlayDropdown #overlayDropdown .p_blackScreen {
  opacity: 1;
  visibility: visible;
}
.showOverlayDropdown #overlayDropdown .wrapper {
  bottom: 0%;
  overflow-y: auto;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li {
  opacity: 1;
  transform: none;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li.a1 {
  transition: opacity 500ms linear 300ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 300ms;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li.a2 {
  transition: opacity 500ms linear 325ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 325ms;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li.a3 {
  transition: opacity 500ms linear 350ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 350ms;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li.a4 {
  transition: opacity 500ms linear 375ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 375ms;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li.a5 {
  transition: opacity 500ms linear 400ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 400ms;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li.a6 {
  transition: opacity 500ms linear 425ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 425ms;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li.a7 {
  transition: opacity 500ms linear 450ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 450ms;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li.a8 {
  transition: opacity 500ms linear 475ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 475ms;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li.a9 {
  transition: opacity 500ms linear 500ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 500ms;
}
.showOverlayDropdown #overlayDropdown .wrapper ul li.a10 {
  transition: opacity 500ms linear 525ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1) 525ms;
}
.showOverlayDropdown header.style1 .headerLinks, .showOverlayDropdown header.style2 .headerLinks {
  color: #343b48;
}
.showOverlayDropdown header.style1 .btn, .showOverlayDropdown header.style2 .btn {
  color: #fff;
}
.showOverlayDropdown header.style1 .btn:before, .showOverlayDropdown header.style2 .btn:before {
  background: #f66f62;
}
.showOverlayDropdown header .grid .itemLinks .rond:after {
  border-color: rgba(52, 59, 72, 0.2);
}
.showOverlayDropdown .btnDropdown:after {
  transform: rotate(-180deg);
}

#overlayShare {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  visibility: hidden;
  z-index: 2001;
  pointer-events: none;
}
#overlayShare::-webkit-scrollbar {
  display: none;
}
#overlayShare .wrapper {
  position: absolute;
  padding: 85px;
  top: 50%;
  left: 50%;
  background: #fff;
  pointer-events: none;
  text-align: center;
  z-index: 151;
  opacity: 0;
  transform: translate(-50%, -25%);
  transition: opacity 300ms, transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 1024px) {
  #overlayShare .wrapper {
    padding: 50px;
  }
}
@media screen and (max-width: 600px) {
  #overlayShare .wrapper {
    width: auto;
    right: 20px;
    left: 20px;
    padding: 50px 20px;
    transform: translateY(-25%);
  }
}
#overlayShare .wrapper h3 {
  margin-bottom: 20px;
}
#overlayShare .wrapper .close {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 20px;
  right: 20px;
}
#overlayShare .wrapper .close:hover .x:before, #overlayShare .wrapper .close:hover .x:after {
  background: #343b48;
}
#overlayShare .wrapper .close .x {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
}
#overlayShare .wrapper .close .x:before, #overlayShare .wrapper .close .x:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #f66f62;
  transition: background 300ms;
}
#overlayShare .wrapper .close .x:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
#overlayShare .wrapper .close .x:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
#overlayShare .wrapper ul {
  position: relative;
  margin-top: 16px;
}
#overlayShare .wrapper ul li {
  display: inline-block;
}
#overlayShare .wrapper ul li:not(:last-child) {
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
  #overlayShare .wrapper ul li:not(:last-child) {
    margin-right: 10px;
  }
}
#overlayShare .wrapper ul li a {
  position: relative;
  width: 45px;
  height: 45px;
  background: #f66f62;
  border-radius: 50%;
  transition: background 300ms;
}
#overlayShare .wrapper ul li a svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  fill: #fff;
  transform: translate(-50%, -50%);
}
#overlayShare .wrapper ul li a svg use {
  fill: #fff;
}
#overlayShare .wrapper ul li a:hover {
  background: #343b48;
}

.showOverlayShare.closingShare #overlayShare .p_blackScreen {
  opacity: 0;
}
.showOverlayShare.closingShare #overlayShare .wrapper {
  opacity: 0;
  transform: translate(-50%, -25%);
}
@media screen and (max-width: 600px) {
  .showOverlayShare.closingShare #overlayShare .wrapper {
    transform: translateY(-25%);
  }
}
.showOverlayShare #overlayShare {
  visibility: visible;
}
.showOverlayShare #overlayShare .p_blackScreen {
  opacity: 1;
  visibility: visible;
  z-index: 150;
  pointer-events: all;
  transition: opacity 600ms;
}
.showOverlayShare #overlayShare .wrapper {
  opacity: 1;
  pointer-events: all;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
  .showOverlayShare #overlayShare .wrapper {
    transform: translateY(-50%);
  }
}

header {
  position: absolute;
  width: 100%;
  padding: 40px 75px 0;
  z-index: 10;
}
@media screen and (max-width: 1300px) {
  header {
    padding: 40px 60px 0;
  }
}
@media screen and (max-width: 1100px) {
  header {
    padding: 30px 60px 0;
  }
}
@media screen and (max-width: 1024px) {
  header {
    padding: 30px 50px 0;
  }
}
@media screen and (max-width: 768px) {
  header {
    padding: 25px 30px 0;
  }
}
@media screen and (max-width: 600px) {
  header {
    padding: 20px 25px 0;
  }
}
header .grid {
  position: relative;
  align-items: center;
}
header .grid .itemLogo {
  width: 210px;
}
@media screen and (max-width: 1300px) {
  header .grid .itemLogo {
    width: 190px;
  }
}
@media screen and (max-width: 1200px) {
  header .grid .itemLogo {
    width: 175px;
  }
}
header .grid .itemLogo a {
  position: relative;
  font-size: 0;
}
header .grid .itemLogo a img {
  width: 210px;
  transition: opacity 300ms;
}
@media screen and (max-width: 1300px) {
  header .grid .itemLogo a img {
    width: 190px;
  }
}
@media screen and (max-width: 1200px) {
  header .grid .itemLogo a img {
    width: 175px;
  }
}
header .grid .itemLinks {
  width: calc(100% - 210px);
  text-align: right;
}
@media screen and (max-width: 1300px) {
  header .grid .itemLinks {
    display: none;
  }
}
header .grid .itemLinks a {
  vertical-align: middle;
}
header .grid .itemLinks a:not(:first-child) {
  margin-left: 40px;
}
@media screen and (max-width: 1400px) {
  header .grid .itemLinks a:not(:first-child) {
    margin-left: 30px;
  }
}
header .grid .itemLinks .rond {
  position: relative;
  width: 50px;
  height: 50px;
  font-size: 0;
}
header .grid .itemLinks .rond:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1), border-color 300ms;
}
header .grid .itemLinks .rond:hover:after {
  transform: scale(0.9);
}
header .grid .itemLinks .rond svg {
  position: absolute;
  width: 20px;
  height: 18px;
  top: 50%;
  left: 50%;
  fill: #f66f62;
  z-index: 1;
  transform: translate(-50%, -50%);
}
header .grid .itemLinks .headerLinks {
  font: 0.9375rem/1 "gtWalsheim", sans-serif;
  transition: color 300ms;
}
header .grid .itemLinks .headerLinks:hover {
  color: #f66f62;
}
header .grid .itemLinks .btnDropdown {
  position: relative;
  padding-right: 20px;
}
header .grid .itemLinks .btnDropdown:after {
  content: "";
  position: absolute;
  right: 0;
  top: 6px;
  transition: transform 300ms;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-top-color: #f66f62;
}
header.style1 .white {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
header.style1 .headerLinks {
  color: #343b48;
}
header.style2 .black {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
header.style2 .headerLinks {
  color: #fff;
}

.btnMenu {
  display: none;
  position: fixed;
  width: 46px;
  height: 46px;
  top: 30px;
  right: 60px;
  z-index: 161;
}
@media screen and (max-width: 1300px) {
  .btnMenu {
    display: block;
  }
}
@media screen and (max-width: 1024px) {
  .btnMenu {
    right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .btnMenu {
    top: 25px;
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  .btnMenu {
    top: 20px;
    right: 25px;
  }
}
.btnMenu:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 50%;
  border: 1px solid rgba(52, 59, 72, 0);
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1), border-color 300ms;
}

footer .content {
  padding-top: 100px;
  padding-bottom: 60px;
  background: #343b48;
}
@media screen and (max-width: 1024px) {
  footer .content {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  footer .content {
    padding-top: 70px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 500px) {
  footer .content {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}
footer .title {
  margin-bottom: 20px;
  font-family: "helveticaNeue", sans-serif;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 1.25rem;
}
@media screen and (max-width: 768px) {
  footer .title {
    margin-bottom: 15px;
  }
}
footer .itemInfo {
  margin-right: auto;
}
@media screen and (max-width: 800px) {
  footer .itemInfo {
    max-width: 185px;
  }
}
@media screen and (max-width: 600px) {
  footer .itemInfo {
    width: 100%;
    max-width: none;
    text-align: center;
  }
}
@media screen and (max-width: 1300px) {
  footer .itemInfo .text {
    max-width: 185px;
  }
}
@media screen and (max-width: 768px) {
  footer .itemInfo .text {
    max-width: 100%;
  }
}
@media screen and (max-width: 375px) {
  footer .itemInfo .text {
    max-width: 185px;
    margin: 0 auto;
  }
}
footer .itemInfo .text, footer .itemInfo a {
  font: 1rem/1.5 "gtWalsheim", sans-serif;
  color: #fff;
}
@media screen and (max-width: 500px) {
  footer .itemInfo .text, footer .itemInfo a {
    font-size: 0.9375rem;
  }
}
footer .itemInfo a {
  margin-top: 30px;
  transition: color 300ms;
}
@media screen and (max-width: 768px) {
  footer .itemInfo a {
    margin-top: 15px;
  }
}
@media screen and (max-width: 500px) {
  footer .itemInfo a {
    margin-top: 10px;
  }
}
footer .itemInfo a:hover {
  color: #f66f62;
}
footer .itemLogos {
  display: flex;
  align-items: flex-end;
  gap: 20px 30px;
  margin: 0 50px;
}
@media screen and (max-width: 1440px) {
  footer .itemLogos {
    flex-direction: column;
  }
}
@media screen and (max-width: 1200px) {
  footer .itemLogos {
    flex-direction: row;
  }
}
@media screen and (max-width: 1100px) {
  footer .itemLogos {
    flex-direction: column;
  }
}
@media screen and (max-width: 850px) {
  footer .itemLogos {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    order: 1;
    margin: 50px 0 0;
  }
}
@media screen and (max-width: 600px) {
  footer .itemLogos {
    order: initial;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  footer .itemLogos {
    gap: 20px 25px;
  }
}
@media screen and (max-width: 400px) {
  footer .itemLogos {
    flex-direction: column;
    justify-content: normal;
    align-items: center;
  }
}
footer .itemLogo--saint-charles-borromee img {
  width: 90px;
}
footer .itemLogo--lanaudiere-osez-grand-vivez-grand img {
  width: 200px;
}
footer .itemLinks {
  margin-right: 50px;
}
@media screen and (max-width: 1200px) {
  footer .itemLinks {
    display: none;
  }
}
footer .itemLinks div:not(:nth-child(2)) {
  margin-top: 5px;
}
footer .itemLinks a {
  font: 1rem/1.5 "gtWalsheim", sans-serif;
  color: #fff;
  transition: color 300ms;
}
footer .itemLinks a:hover {
  color: #f66f62;
}
footer .itemNewsletter {
  width: 260px;
}
@media screen and (max-width: 600px) {
  footer .itemNewsletter {
    width: 100%;
    margin-top: 50px;
  }
}
@media screen and (max-width: 850px) {
  footer .itemNewsletter .wrapper {
    max-width: 260px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 425px) {
  footer .itemNewsletter .wrapper {
    max-width: 100%;
    margin: 0;
  }
}
footer .itemNewsletter form fieldset {
  position: relative;
}
footer .itemNewsletter form fieldset > input {
  font-size: 0;
  position: absolute;
  top: 10px;
  right: 0;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-left-color: #f66f62;
}
footer .itemNewsletter .medias a:hover svg {
  fill: #f66f62;
}
footer .itemNewsletter .medias svg {
  width: 24px;
  height: 24px;
  fill: #fff;
  vertical-align: middle;
  transition: fill 300ms;
}
footer .itemNewsletter .medias .facebook {
  margin-right: 10px;
}
footer .itemNewsletter .medias .facebook svg {
  height: 22px;
}
footer .itemNewsletter input:-webkit-autofill, footer .itemNewsletter input:-webkit-autofill:hover, footer .itemNewsletter input:-webkit-autofill:focus input:-webkit-autofill {
  border: none;
  border-bottom: 1px solid #fff;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #343b48 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.s_copyright .content {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #343b48;
}
@media screen and (max-width: 500px) {
  .s_copyright .content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.s_copyright .itemCopyright {
  width: calc(100% - 120px);
}
.s_copyright .itemBlanko {
  width: 120px;
  text-align: right;
}
@media screen and (max-width: 500px) {
  .s_copyright .itemBlanko {
    margin-top: 10px;
  }
}
@media screen and (max-width: 500px) {
  .s_copyright .itemCopyright, .s_copyright .itemBlanko {
    width: 100%;
    text-align: center;
  }
}
.s_copyright p, .s_copyright a {
  font: 0.875rem/1 "gtWalsheim", sans-serif;
  color: rgba(255, 255, 255, 0.5);
}
.s_copyright a {
  display: block;
  transition: color 300ms;
}
.s_copyright a:hover {
  color: #fff;
}

.s_homeBanner {
  position: relative;
  height: calc(var(--vh, 1vh) * 100 - 20px);
  min-height: 630px;
  padding-top: 100px;
  background: #f1efed;
}
@media screen and (max-width: 1024px) {
  .s_homeBanner {
    padding-top: 84px;
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner {
    height: 100%;
    min-height: 100%;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .s_homeBanner {
    padding-top: 79px;
  }
}
@media screen and (max-width: 600px) {
  .s_homeBanner {
    padding-top: 74px;
  }
}
.s_homeBanner .content {
  position: relative;
  height: 100%;
}
.s_homeBanner .slickTitles, .s_homeBanner .slickImages {
  margin-bottom: 0;
}
.s_homeBanner .slick-slider, .s_homeBanner .slick-list, .s_homeBanner .slick-track, .s_homeBanner .slick-slide {
  font-size: 0;
}
.s_homeBanner .slickNext, .s_homeBanner .scrollDown {
  position: absolute;
  width: 50px;
  height: 50px;
}
.s_homeBanner .slickNext:hover:before, .s_homeBanner .scrollDown:hover:before {
  transform: scale(0.9);
}
.s_homeBanner .slickNext:before, .s_homeBanner .scrollDown:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  border-radius: 50%;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.s_homeBanner .slickNext {
  top: 50%;
  left: 1140px;
  transform: translateY(-50%);
  transition: transform 300ms;
  z-index: 1;
}
@media screen and (max-width: 1500px) {
  .s_homeBanner .slickNext {
    left: 1000px;
  }
}
@media screen and (max-width: 1400px) {
  .s_homeBanner .slickNext {
    left: 900px;
  }
}
@media screen and (max-width: 1200px) {
  .s_homeBanner .slickNext {
    left: 805px;
  }
}
@media screen and (max-width: 1100px) {
  .s_homeBanner .slickNext {
    left: 765px;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .slickNext {
    left: 670px;
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner .slickNext {
    left: 525px;
  }
}
@media screen and (max-width: 768px) {
  .s_homeBanner .slickNext {
    left: 505px;
  }
}
@media screen and (max-width: 600px) {
  .s_homeBanner .slickNext {
    left: 400px;
  }
}
@media screen and (max-width: 500px) {
  .s_homeBanner .slickNext {
    left: 305px;
  }
}
@media screen and (max-width: 375px) {
  .s_homeBanner .slickNext {
    left: 260px;
  }
}
.s_homeBanner .slickNext svg {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  stroke: #f66f62;
  transform: translate(-50%, -50%) rotate(-90deg);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.s_homeBanner .scrollDown {
  bottom: 20px;
  left: 20px;
}
@media screen and (max-width: 950px) {
  .s_homeBanner .scrollDown {
    display: none;
  }
}
.s_homeBanner .scrollDown span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-top-color: #f66f62;
}
.s_homeBanner .slickDots {
  position: absolute;
  left: 420px;
  bottom: 26px;
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .s_homeBanner .slickDots {
    left: 375px;
  }
}
@media screen and (max-width: 1100px) {
  .s_homeBanner .slickDots {
    left: 335px;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .slickDots {
    left: 265px;
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner .slickDots {
    display: none;
  }
}
.s_homeBanner .slickDots ul {
  position: relative;
  bottom: 0;
}
.s_homeBanner .slickDots ul li {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0;
}
.s_homeBanner .slickDots ul li.slick-active button {
  border-color: #f66f62;
  background: #f66f62;
}
.s_homeBanner .slickDots ul li button {
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
  border: 1px solid rgba(52, 59, 72, 0.5);
  transition: border-color 300ms, background 300ms;
}
.s_homeBanner .slickDots ul li button:before {
  display: none;
}
.s_homeBanner .titlesWrapper {
  position: absolute;
  width: 580px;
  min-height: 480px;
  top: 50%;
  left: 120px;
  transform: translateY(-50%);
  z-index: 1;
}
@media screen and (max-width: 1300px) {
  .s_homeBanner .titlesWrapper {
    width: 530px;
  }
}
@media screen and (max-width: 1200px) {
  .s_homeBanner .titlesWrapper {
    width: 500px;
    min-height: 450px;
    left: 100px;
  }
}
@media screen and (max-height: 800px) {
  .s_homeBanner .titlesWrapper {
    min-height: 420px;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .titlesWrapper {
    width: 430px;
    min-height: 400px;
    left: 50px;
  }
}
@media screen and (max-height: 700px) {
  .s_homeBanner .titlesWrapper {
    min-height: 360px;
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner .titlesWrapper {
    position: relative;
    width: 100%;
    min-height: inherit;
    padding: 50px 50px 0;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    transform: none;
  }
}
@media screen and (max-width: 768px) {
  .s_homeBanner .titlesWrapper {
    padding: 50px 30px 0;
  }
}
@media screen and (max-width: 600px) {
  .s_homeBanner .titlesWrapper {
    padding: 50px 25px 0;
  }
}
.s_homeBanner .titlesWrapper:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 80px;
  background: #fff;
}
@media screen and (max-width: 1300px) {
  .s_homeBanner .titlesWrapper:after {
    left: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .titlesWrapper:after {
    left: 30px;
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner .titlesWrapper:after {
    display: none;
  }
}
.s_homeBanner .titlesWrapper .slick-slide a {
  margin: 25px 0;
}
.s_homeBanner .titlesWrapper .slickTitles {
  position: relative;
  z-index: 1;
}
.s_homeBanner .titlesWrapper .slickTitles .text {
  display: flex;
  align-items: center;
  min-height: 480px;
  padding-right: 50px;
}
@media screen and (max-width: 1200px) {
  .s_homeBanner .titlesWrapper .slickTitles .text {
    min-height: 450px;
  }
}
@media screen and (max-height: 800px) {
  .s_homeBanner .titlesWrapper .slickTitles .text {
    min-height: 420px;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .titlesWrapper .slickTitles .text {
    min-height: 400px;
  }
}
@media screen and (max-height: 700px) {
  .s_homeBanner .titlesWrapper .slickTitles .text {
    min-height: 360px;
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner .titlesWrapper .slickTitles .text {
    min-height: 100%;
    max-width: 500px;
    padding-right: 0;
  }
}
.s_homeBanner .titlesWrapper .slickTitles h1, .s_homeBanner .titlesWrapper .slickTitles .h1 {
  color: #343b48;
  font-size: 5.625rem;
}
@media screen and (max-height: 800px) {
  .s_homeBanner .titlesWrapper .slickTitles h1, .s_homeBanner .titlesWrapper .slickTitles .h1 {
    font-size: 4.6875rem;
  }
}
@media screen and (max-width: 1440px) {
  .s_homeBanner .titlesWrapper .slickTitles h1, .s_homeBanner .titlesWrapper .slickTitles .h1 {
    font-size: 4.6875rem;
  }
}
@media screen and (max-height: 700px) {
  .s_homeBanner .titlesWrapper .slickTitles h1, .s_homeBanner .titlesWrapper .slickTitles .h1 {
    font-size: 3.75rem;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .titlesWrapper .slickTitles h1, .s_homeBanner .titlesWrapper .slickTitles .h1 {
    font-size: 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  .s_homeBanner .titlesWrapper .slickTitles h1, .s_homeBanner .titlesWrapper .slickTitles .h1 {
    font-size: 3.125rem;
  }
}
@media screen and (max-width: 425px) {
  .s_homeBanner .titlesWrapper .slickTitles h1, .s_homeBanner .titlesWrapper .slickTitles .h1 {
    font-size: 2.8125rem;
  }
}
@media screen and (max-width: 375px) {
  .s_homeBanner .titlesWrapper .slickTitles h1, .s_homeBanner .titlesWrapper .slickTitles .h1 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 320px) {
  .s_homeBanner .titlesWrapper .slickTitles h1, .s_homeBanner .titlesWrapper .slickTitles .h1 {
    font-size: 2.3125rem;
  }
}
.s_homeBanner .titlesWrapper .slickTitles h1.smaller, .s_homeBanner .titlesWrapper .slickTitles .h1.smaller {
  font-size: 4.0625rem;
}
@media screen and (max-width: 1440px) {
  .s_homeBanner .titlesWrapper .slickTitles h1.smaller, .s_homeBanner .titlesWrapper .slickTitles .h1.smaller {
    font-size: 3.125rem;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .titlesWrapper .slickTitles h1.smaller, .s_homeBanner .titlesWrapper .slickTitles .h1.smaller {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 768px) {
  .s_homeBanner .titlesWrapper .slickTitles h1.smaller, .s_homeBanner .titlesWrapper .slickTitles .h1.smaller {
    font-size: 2.1875rem;
  }
}
@media screen and (max-width: 425px) {
  .s_homeBanner .titlesWrapper .slickTitles h1.smaller, .s_homeBanner .titlesWrapper .slickTitles .h1.smaller {
    font-size: 1.875rem;
  }
}
.s_homeBanner .titlesWrapper .slickTitles .link {
  margin-top: 40px;
  margin-bottom: 2px;
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .titlesWrapper .slickTitles .link {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .s_homeBanner .titlesWrapper .slickTitles .link {
    margin-top: 20px;
  }
}
.s_homeBanner .imagesWrapper {
  position: absolute;
  width: calc(100% - 200px);
  height: 100%;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 1300px) {
  .s_homeBanner .imagesWrapper {
    width: calc(100% - 170px);
  }
}
@media screen and (max-width: 1200px) {
  .s_homeBanner .imagesWrapper {
    width: calc(100% - 150px);
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner .imagesWrapper {
    position: relative;
    width: 100%;
    top: 0;
    transform: none;
  }
}
.s_homeBanner .imagesWrapper .slick-list, .s_homeBanner .imagesWrapper .slick-track, .s_homeBanner .imagesWrapper .slick-slide {
  height: 100%;
}
.s_homeBanner .imagesWrapper .slick-slide, .s_homeBanner .imagesWrapper .slick-slide > div, .s_homeBanner .imagesWrapper .slick-slide > div > div {
  height: 100%;
}
.s_homeBanner .imagesWrapper .slick-slide > div > div {
  display: flex !important;
  align-items: center;
}
.s_homeBanner .imagesWrapper .slick-list {
  padding: 0 80px 0 425px;
}
@media screen and (max-width: 1200px) {
  .s_homeBanner .imagesWrapper .slick-list {
    padding: 0 80px 0 380px;
  }
}
@media screen and (max-width: 1100px) {
  .s_homeBanner .imagesWrapper .slick-list {
    padding: 0 80px 0 340px;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .imagesWrapper .slick-list {
    padding: 0 80px 0 270px;
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner .imagesWrapper .slick-list {
    padding: 0 0 0 50px;
  }
}
@media screen and (max-width: 768px) {
  .s_homeBanner .imagesWrapper .slick-list {
    padding: 0 0 0 30px;
  }
}
@media screen and (max-width: 600px) {
  .s_homeBanner .imagesWrapper .slick-list {
    padding: 0 0 0 25px;
  }
}
.s_homeBanner .imagesWrapper .slickImages {
  position: absolute;
  width: 100%;
  top: 60px;
  bottom: 60px;
  left: 0;
}
@media screen and (max-width: 950px) {
  .s_homeBanner .imagesWrapper .slickImages {
    position: relative;
    top: 0;
    bottom: 0;
  }
}
.s_homeBanner .imagesWrapper .slickImages picture {
  position: relative;
  width: 660px;
  height: 460px;
  transition: width 700ms cubic-bezier(0.215, 0.61, 0.355, 1), height 700ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 1200px) {
  .s_homeBanner .imagesWrapper .slickImages picture {
    height: 448px;
  }
}
@media screen and (max-height: 800px) {
  .s_homeBanner .imagesWrapper .slickImages picture {
    height: 418px;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .imagesWrapper .slickImages picture {
    height: 398px;
  }
}
@media screen and (max-height: 700px) {
  .s_homeBanner .imagesWrapper .slickImages picture {
    height: 358px;
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner .imagesWrapper .slickImages picture {
    height: 350px;
  }
}
@media screen and (max-width: 500px) {
  .s_homeBanner .imagesWrapper .slickImages picture {
    height: 275px;
  }
}
.s_homeBanner .imagesWrapper .slickImages picture .copyright {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font: 0.8125rem/1 "gtWalsheim", sans-serif;
  color: #d6d8da;
}
@media screen and (max-width: 1200px) {
  .s_homeBanner .imagesWrapper .slickImages picture .copyright {
    bottom: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .imagesWrapper .slickImages picture .copyright {
    bottom: 70px;
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner .imagesWrapper .slickImages picture .copyright {
    bottom: 20px;
  }
}
.s_homeBanner .imagesWrapper .slickImages .slick-slide {
  position: relative;
  padding-right: 80px;
}
@media screen and (max-width: 1500px) {
  .s_homeBanner .imagesWrapper .slickImages .slick-slide {
    width: 600px;
  }
}
@media screen and (max-width: 1400px) {
  .s_homeBanner .imagesWrapper .slickImages .slick-slide {
    width: 500px;
    padding-right: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .s_homeBanner .imagesWrapper .slickImages .slick-slide {
    width: 450px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .s_homeBanner .imagesWrapper .slickImages .slick-slide {
    width: 425px;
  }
}
@media screen and (max-width: 950px) {
  .s_homeBanner .imagesWrapper .slickImages .slick-slide {
    width: 500px;
    height: 450px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 600px) {
  .s_homeBanner .imagesWrapper .slickImages .slick-slide {
    padding-right: 30px;
    width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .s_homeBanner .imagesWrapper .slickImages .slick-slide {
    width: 300px;
    height: 350px;
  }
}
.s_homeBanner .imagesWrapper .slickImages .slick-current picture, .s_homeBanner .imagesWrapper .slickImages .slickAnimate picture {
  width: 660px;
  height: 100%;
}
@media screen and (max-width: 950px) {
  .s_homeBanner .imagesWrapper .slickImages .slick-current picture, .s_homeBanner .imagesWrapper .slickImages .slickAnimate picture {
    height: 450px;
  }
}
@media screen and (max-width: 500px) {
  .s_homeBanner .imagesWrapper .slickImages .slick-current picture, .s_homeBanner .imagesWrapper .slickImages .slickAnimate picture {
    height: 350px;
  }
}

.s_smallInfosArea {
  padding: 50px 0;
  background: #f66f62;
}
.s_smallInfosArea .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .s_smallInfosArea .wrapper {
    display: block;
    text-align: center;
  }
}
.s_smallInfosArea .button {
  margin-left: 70px;
}
@media screen and (max-width: 1024px) {
  .s_smallInfosArea .button {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .s_smallInfosArea .button {
    margin-left: 0;
    margin-top: 20px;
  }
}
.s_smallInfosArea p {
  color: #fff;
  font-size: 1.375rem;
}
@media screen and (max-width: 500px) {
  .s_smallInfosArea p {
    line-height: 1.3;
  }
}

.s_textCenter {
  background: #343b48;
}
.s_textCenter .wrapper {
  max-width: 768px;
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .s_textCenter .wrapper {
    margin: 0;
  }
}
.s_textCenter .wrapper h2 {
  font-weight: 300;
  font-family: "gtWalsheim", sans-serif;
  color: #fff;
}
@media screen and (max-width: 375px) {
  .s_textCenter .wrapper h2 {
    font-size: 1.5625rem;
  }
}
.s_textCenter .wrapper a {
  margin-top: 40px;
}

.s_textAndMosaic {
  position: relative;
  background: #f1efed;
}
.s_textAndMosaic .hugeTitle {
  position: absolute;
  font-family: "helveticaNeue", sans-serif;
  font-size: 25.3125rem;
  font-weight: 700;
  color: #fff;
  opacity: 0.4;
}
@media screen and (max-width: 1400px) {
  .s_textAndMosaic .hugeTitle {
    font-size: 23.125rem;
  }
}
@media screen and (max-width: 1200px) {
  .s_textAndMosaic .hugeTitle {
    font-size: 20rem;
  }
}
@media screen and (max-width: 1024px) {
  .s_textAndMosaic .hugeTitle {
    font-size: 16.25rem;
  }
}
@media screen and (max-width: 768px) {
  .s_textAndMosaic .hugeTitle {
    display: none;
  }
}
.s_textAndMosaic .hugeTitle.part1 {
  top: 50px;
  left: 50px;
}
@media screen and (max-width: 1200px) {
  .s_textAndMosaic .hugeTitle.part1 {
    top: 75px;
  }
}
@media screen and (max-width: 1024px) {
  .s_textAndMosaic .hugeTitle.part1 {
    top: 90px;
  }
}
.s_textAndMosaic .hugeTitle.part2 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.s_textAndMosaic .hugeTitle.part3 {
  right: 150px;
  bottom: 140px;
}
@media screen and (max-width: 1400px) {
  .s_textAndMosaic .hugeTitle.part3 {
    right: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .s_textAndMosaic .hugeTitle.part3 {
    bottom: 100px;
  }
}
.s_textAndMosaic .content {
  position: relative;
  z-index: 1;
  padding-top: 300px;
  padding-bottom: 340px;
}
@media screen and (max-width: 1200px) {
  .s_textAndMosaic .content {
    padding-top: 280px;
    padding-bottom: 300px;
  }
}
@media screen and (max-width: 1024px) {
  .s_textAndMosaic .content {
    padding-top: 270px;
    padding-bottom: 230px;
  }
}
@media screen and (max-width: 768px) {
  .s_textAndMosaic .content {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 600px) {
  .s_textAndMosaic .content {
    padding-top: 0;
    padding-bottom: 50px;
  }
}
.s_textAndMosaic .col5, .s_textAndMosaic .col7 {
  display: flex;
  align-items: center;
}
.s_textAndMosaic .col5 {
  position: relative;
}
.s_textAndMosaic .text {
  max-width: 300px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .s_textAndMosaic .text {
    max-width: 100%;
    margin: 0;
    padding: 0 30px;
  }
}
@media screen and (max-width: 600px) {
  .s_textAndMosaic .text {
    padding: 45px 25px 0 25px;
  }
}
.s_textAndMosaic .text p {
  font-size: 1rem;
}
.s_textAndMosaic .text p, .s_textAndMosaic .text a {
  margin-top: 50px;
}
@media screen and (max-width: 1200px) {
  .s_textAndMosaic .text p, .s_textAndMosaic .text a {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .s_textAndMosaic .text p, .s_textAndMosaic .text a {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .s_textAndMosaic .text p, .s_textAndMosaic .text a {
    margin-top: 20px;
  }
}
.s_textAndMosaic .main {
  width: 100%;
  height: 600px;
}
@media screen and (max-width: 1400px) {
  .s_textAndMosaic .main {
    height: 560px;
  }
}
@media screen and (max-width: 1200px) {
  .s_textAndMosaic .main {
    height: 500px;
  }
}
@media screen and (max-width: 1024px) {
  .s_textAndMosaic .main {
    height: 450px;
  }
}
@media screen and (max-width: 768px) {
  .s_textAndMosaic .main {
    height: 400px;
  }
}
@media screen and (max-width: 600px) {
  .s_textAndMosaic .main {
    height: 350px;
  }
}
@media screen and (max-width: 425px) {
  .s_textAndMosaic .main {
    height: 300px;
  }
}
@media screen and (max-width: 320px) {
  .s_textAndMosaic .main {
    height: 250px;
  }
}
.s_textAndMosaic .small {
  position: absolute;
}
@media screen and (max-width: 768px) {
  .s_textAndMosaic .small {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .s_textAndMosaic .first .col7 {
    order: 2;
  }
}
@media screen and (max-width: 600px) {
  .s_textAndMosaic .first .col5 {
    order: 1;
  }
}
.s_textAndMosaic .first .small {
  width: 260px;
  height: 340px;
  bottom: 100%;
  right: 100%;
}
@media screen and (max-width: 1200px) {
  .s_textAndMosaic .first .small {
    width: 240px;
    height: 300px;
  }
}
@media screen and (max-width: 1200px) {
  .s_textAndMosaic .first .small {
    width: 150px;
    height: 190px;
  }
}
.s_textAndMosaic .last {
  margin-top: 110px;
}
@media screen and (max-width: 1200px) {
  .s_textAndMosaic .last {
    margin-top: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .s_textAndMosaic .last {
    margin-top: 75px;
  }
}
@media screen and (max-width: 600px) {
  .s_textAndMosaic .last {
    margin-top: 50px;
  }
}
.s_textAndMosaic .last .col7 {
  order: 2;
}
.s_textAndMosaic .last .col5 {
  order: 1;
}
.s_textAndMosaic .last .small {
  width: 200px;
  height: 150px;
  top: 100%;
  left: 100%;
}

.s_banner {
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .s_banner {
    height: 475px;
  }
}
.s_banner .content {
  position: relative;
}
.s_banner .w1200 {
  position: relative;
}
.s_banner .grid {
  max-width: 800px;
  height: calc(var(--vh, 1vh) * 80);
  min-height: 500px;
  padding-top: 100px;
  padding-bottom: 120px;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  transition: height 300ms;
}
@media screen and (max-width: 1024px) {
  .s_banner .grid {
    padding-top: 84px;
  }
}
@media screen and (max-width: 768px) {
  .s_banner .grid {
    padding-top: 79px;
  }
}
@media screen and (max-width: 600px) {
  .s_banner .grid {
    padding-top: 74px;
  }
}
.s_banner .item {
  width: 100%;
}
.s_banner .item p {
  margin: 30px 0px 20px;
  color: #fff;
}
.s_banner span {
  color: #fff;
  font-family: "gtWalsheim", sans-serif;
  font-size: 0.875rem !important;
  line-height: 1.75;
  position: absolute;
  bottom: 25px;
  right: 0;
  padding-right: 30px;
}
.s_banner .copyright {
  position: absolute;
  right: 0;
  bottom: 140px;
  font: 0.8125rem/1 "gtWalsheim", sans-serif;
  color: #d6d8da;
}
@media screen and (max-width: 1200px) {
  .s_banner .copyright {
    bottom: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .s_banner .copyright {
    bottom: 70px;
  }
}
@media screen and (max-width: 950px) {
  .s_banner .copyright {
    bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  .s_banner .copyright {
    bottom: 220px;
  }
}
@media screen and (max-width: 600px) and (max-height: 650px) {
  .s_banner .copyright {
    bottom: 70px;
  }
}
.s_banner .btnShare {
  font: 0.8125rem/1 "gtWalsheim", sans-serif;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 40px;
  transition: color 300ms;
}
.s_banner .btnShare:hover {
  color: #f66f62;
}
.s_banner .btnShare:hover svg {
  fill: #f66f62;
}
.s_banner .btnShare svg {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  fill: #fff;
  transition: fill 300ms;
}
.s_banner picture, .s_banner figure {
  position: absolute;
  width: 100%;
  min-height: 600px;
  top: -50px;
  bottom: -50px;
  left: 0;
}
.s_banner picture:after, .s_banner figure:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(52, 59, 72, 0.7);
}

.s_pageTitle {
  position: relative;
  padding-top: 100px;
  background: #f1efed;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .s_pageTitle {
    padding-top: 84px;
  }
}
@media screen and (max-width: 768px) {
  .s_pageTitle {
    padding-top: 79px;
  }
}
@media screen and (max-width: 600px) {
  .s_pageTitle {
    padding-top: 74px;
  }
}
.s_pageTitle .w1200 {
  position: relative;
}
.s_pageTitle .grid.titleLogo {
  padding-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .s_pageTitle .grid.titleLogo {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 500px) {
  .s_pageTitle .grid.titleLogo {
    padding-bottom: 50px;
  }
}
.s_pageTitle .grid.titleLogo .itemTitle {
  width: calc(100% - 300px);
}
@media screen and (max-width: 1200px) {
  .s_pageTitle .grid.titleLogo .itemTitle {
    width: calc(100% - 255px);
  }
}
@media screen and (max-width: 900px) {
  .s_pageTitle .grid.titleLogo .itemTitle {
    width: calc(100% - 206px);
  }
}
@media screen and (max-width: 620px) {
  .s_pageTitle .grid.titleLogo .itemTitle {
    width: 100%;
  }
}
.s_pageTitle .grid.titleLogo .itemTitle h1 {
  margin-right: 50px;
  color: #343b48;
}
@media screen and (max-width: 768px) {
  .s_pageTitle .grid.titleLogo .itemTitle h1 {
    margin-right: 25px;
  }
}
@media screen and (max-width: 620px) {
  .s_pageTitle .grid.titleLogo .itemTitle h1 {
    margin-right: 0;
  }
}
.s_pageTitle .grid.titleLogo .itemLogos {
  display: flex;
  width: 300px;
  align-items: flex-end;
}
@media screen and (max-width: 1200px) {
  .s_pageTitle .grid.titleLogo .itemLogos {
    width: 255px;
  }
}
@media screen and (max-width: 900px) {
  .s_pageTitle .grid.titleLogo .itemLogos {
    width: 206px;
  }
}
@media screen and (max-width: 620px) {
  .s_pageTitle .grid.titleLogo .itemLogos {
    width: 100%;
    margin-top: 20px;
  }
}
.s_pageTitle .grid.titleLogo .itemLogos .scb {
  width: 105px;
  height: 105px;
  margin-right: 40px;
}
@media screen and (max-width: 1200px) {
  .s_pageTitle .grid.titleLogo .itemLogos .scb {
    width: 95px;
    height: 95px;
    margin-right: 35px;
  }
}
@media screen and (max-width: 900px) {
  .s_pageTitle .grid.titleLogo .itemLogos .scb {
    width: 75px;
    height: 75px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 620px) {
  .s_pageTitle .grid.titleLogo .itemLogos .scb {
    width: 95px;
    height: 95px;
    margin-right: 35px;
  }
}
@media screen and (max-width: 425px) {
  .s_pageTitle .grid.titleLogo .itemLogos .scb {
    width: 75px;
    height: 75px;
    margin-right: 20px;
  }
}
.s_pageTitle .grid.titleLogo .itemLogos .desjardins {
  width: 150px;
}
@media screen and (max-width: 1200px) {
  .s_pageTitle .grid.titleLogo .itemLogos .desjardins {
    width: 130px;
  }
}
@media screen and (max-width: 900px) {
  .s_pageTitle .grid.titleLogo .itemLogos .desjardins {
    width: 111px;
  }
}
@media screen and (max-width: 620px) {
  .s_pageTitle .grid.titleLogo .itemLogos .desjardins {
    width: 130px;
  }
}
.s_pageTitle .grid.titleImage .itemTitle {
  width: calc(100% - 520px);
  padding: 50px 50px 50px 0;
}
@media screen and (max-width: 1300px) {
  .s_pageTitle .grid.titleImage .itemTitle {
    width: calc(100% - 400px);
  }
}
@media screen and (max-width: 1100px) {
  .s_pageTitle .grid.titleImage .itemTitle {
    width: calc(100% - 350px);
    padding-right: 30px;
  }
}
@media screen and (max-width: 900px) {
  .s_pageTitle .grid.titleImage .itemTitle {
    width: calc(100% - 300px);
    padding-right: 25px;
  }
}
@media screen and (max-width: 768px) {
  .s_pageTitle .grid.titleImage .itemTitle {
    width: 100%;
    padding: 0 0 20px 0;
  }
}
.s_pageTitle .grid.titleImage .itemTitle h1 {
  margin-bottom: 50px;
  color: #343b48;
}
@media screen and (max-width: 768px) {
  .s_pageTitle .grid.titleImage .itemTitle h1 {
    margin-bottom: 30px;
  }
}
.s_pageTitle .grid.titleImage .itemTitle h6 {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d6d8da;
}
.s_pageTitle .grid.titleImage .itemTitle p {
  color: #343b48;
}
@media screen and (max-width: 768px) {
  .s_pageTitle .grid.titleImage .itemTitle p {
    font-size: 1rem;
  }
}
.s_pageTitle .grid.titleImage .itemTitle p strong {
  font-weight: 700;
}
.s_pageTitle .grid.titleImage .itemPicture {
  position: absolute;
  width: 520px;
  top: 0;
  right: 0;
  bottom: -40px;
}
@media screen and (max-width: 1300px) {
  .s_pageTitle .grid.titleImage .itemPicture {
    width: 400px;
  }
}
@media screen and (max-width: 1100px) {
  .s_pageTitle .grid.titleImage .itemPicture {
    width: 350px;
  }
}
@media screen and (max-width: 900px) {
  .s_pageTitle .grid.titleImage .itemPicture {
    width: 300px;
  }
}
@media screen and (max-width: 768px) {
  .s_pageTitle .grid.titleImage .itemPicture {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    bottom: 0;
  }
}
.s_pageTitle .grid.titleImage .itemPicture picture {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .s_pageTitle .grid.titleImage .itemPicture picture {
    height: 350px;
  }
}
@media screen and (max-width: 600px) {
  .s_pageTitle .grid.titleImage .itemPicture picture {
    height: 300px;
  }
}
.s_pageTitle .grid.titleImage .itemPicture p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px 15px;
  font: 0.8125rem/1 "gtWalsheim", sans-serif;
  color: #fff;
  background: rgba(52, 59, 72, 0.7);
}

.s_dynamic {
  position: relative;
  z-index: 1;
}
.s_dynamic .w1200 {
  position: relative;
  height: calc(100% + 120px);
  margin-top: -120px;
  background: #fff;
}
@media screen and (max-width: 1200px) {
  .s_dynamic .w1200 {
    height: calc(100% - 80px);
    margin-top: -80px;
  }
}
@media screen and (max-width: 1024px) {
  .s_dynamic .w1200 {
    height: calc(100% - 50px);
    margin-top: -50px;
  }
}
@media screen and (max-width: 950px) {
  .s_dynamic .w1200 {
    height: 100%;
    margin-top: 0;
  }
}
.s_dynamic .intro {
  margin-bottom: 40px;
  line-height: 1.3;
  font-family: "gtWalsheim", sans-serif;
  color: #343b48;
  font-size: 1.625rem;
}
@media screen and (max-width: 768px) {
  .s_dynamic .intro {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 425px) {
  .s_dynamic .intro {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 375px) {
  .s_dynamic .intro {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 320px) {
  .s_dynamic .intro {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 1024px) {
  .s_dynamic .intro {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .s_dynamic .intro {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 600px) {
  .s_dynamic .intro {
    margin-bottom: 20px;
  }
}
.s_dynamic .btnShare {
  font: 0.8125rem/1 "gtWalsheim", sans-serif;
  font-weight: 700;
  color: #f66f62;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 40px;
  transition: color 300ms;
}
@media screen and (max-width: 1024px) {
  .s_dynamic .btnShare {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .s_dynamic .btnShare {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 600px) {
  .s_dynamic .btnShare {
    margin-bottom: 20px;
  }
}
.s_dynamic .btnShare:hover {
  color: #343b48;
}
.s_dynamic .btnShare:hover svg {
  fill: #343b48;
}
.s_dynamic .btnShare svg {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  fill: #f66f62;
  transition: fill 300ms;
}
.s_dynamic .info {
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  .s_dynamic .info {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .s_dynamic .info {
    margin-bottom: 30px;
  }
}
.s_dynamic .info h6 {
  margin-bottom: 5px;
}
.s_dynamic .info p {
  color: #343b48;
}

.s_component1 {
  overflow: hidden;
  position: relative;
}
.s_component1 picture {
  position: absolute;
  top: -50px;
  right: 0;
  bottom: -50px;
  left: 0;
}
.s_component1 .grid, .s_component1 .content {
  position: relative;
}
.s_component1 .item {
  width: 460px;
  padding: 80px 60px;
  background: #fff;
}
@media screen and (max-width: 1200px) {
  .s_component1 .item {
    padding: 70px 50px;
  }
}
@media screen and (max-width: 1024px) {
  .s_component1 .item {
    padding: 60px 50px;
  }
}
@media screen and (max-width: 768px) {
  .s_component1 .item {
    padding: 50px 30px;
  }
}
@media screen and (max-width: 500px) {
  .s_component1 .item {
    padding: 30px 25px;
  }
}
.s_component1 .item p {
  font-family: "gtWalsheim", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  color: #343b48;
  font-size: 1.625rem;
}
@media screen and (max-width: 1024px) {
  .s_component1 .item p {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .s_component1 .item p {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 425px) {
  .s_component1 .item p {
    font-size: 1.3125rem;
  }
}
@media screen and (max-width: 375px) {
  .s_component1 .item p {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 320px) {
  .s_component1 .item p {
    font-size: 1.1875rem;
  }
}
@media screen and (max-width: 500px) {
  .s_component1 .item p {
    line-height: 1.3;
  }
}

.s_programmation {
  position: relative;
  background: #fff;
}
.s_programmation .hugeTitle {
  position: absolute;
  top: 170px;
  left: 20px;
  font: 7.8125rem/1 "gtWalsheim", sans-serif;
  font-weight: 700;
  color: #f1efed;
  white-space: nowrap;
}
@media screen and (max-width: 1600px) {
  .s_programmation .hugeTitle {
    top: 150px;
    left: 20px;
    font-size: 6.875rem;
  }
}
@media screen and (max-width: 1400px) {
  .s_programmation .hugeTitle {
    top: 130px;
    left: 20px;
    font-size: 5.625rem;
  }
}
@media screen and (max-width: 1200px) {
  .s_programmation .hugeTitle {
    top: 90px;
    left: 20px;
    font-size: 4.6875rem;
  }
}
@media screen and (max-width: 1024px) {
  .s_programmation .hugeTitle {
    top: 50px;
    left: 25px;
    font-size: 3.25rem;
  }
}
@media screen and (max-width: 768px) {
  .s_programmation .hugeTitle {
    display: block;
    position: relative;
    margin-bottom: 30px;
    top: 0;
    left: 0;
    font: 2.0625rem/1.3 "helveticaNeue", sans-serif;
    font-weight: 700;
    color: #343b48;
    white-space: normal;
  }
}
@media screen and (max-width: 425px) {
  .s_programmation .hugeTitle {
    font-size: 1.9375rem;
  }
}
@media screen and (max-width: 375px) {
  .s_programmation .hugeTitle {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 320px) {
  .s_programmation .hugeTitle {
    font-size: 1.8125rem;
  }
}
@media screen and (max-width: 500px) {
  .s_programmation .w1200 {
    max-width: 300px;
    margin: 0 auto;
  }
}
.s_programmation .col4:nth-child(-n+3) {
  margin-top: 0;
}
.s_programmation .col4:not(:nth-child(-n+3)) {
  margin-top: 90px;
}
@media screen and (max-width: 1200px) {
  .s_programmation .col4:nth-child(-n+3) {
    margin-top: 0;
  }
  .s_programmation .col4:not(:nth-child(-n+3)) {
    margin-top: 70px;
  }
}
@media screen and (max-width: 900px) {
  .s_programmation .col4:nth-child(-n+2) {
    margin-top: 0;
  }
  .s_programmation .col4:not(:nth-child(-n+2)) {
    margin-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .s_programmation .col4:nth-child(-n+1) {
    margin-top: 0;
  }
  .s_programmation .col4:not(:nth-child(-n+1)) {
    margin-top: 40px;
  }
}
@media screen and (max-width: 900px) and (min-width: 500px) {
  .s_programmation .col4:last-child {
    display: none;
  }
}
.s_programmation .wrapper {
  text-align: center;
  margin-top: 90px;
}
@media screen and (max-width: 1200px) {
  .s_programmation .wrapper {
    margin-top: 70px;
  }
}
@media screen and (max-width: 900px) {
  .s_programmation .wrapper {
    margin-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .s_programmation .wrapper {
    margin-top: 30px;
  }
}

.s_publications {
  background: #fff;
}
.s_publications .col6:nth-child(-n+2) {
  margin-top: 0;
}
.s_publications .col6:not(:nth-child(-n+2)) {
  margin-top: 90px;
}
@media screen and (max-width: 1200px) {
  .s_publications .col6:nth-child(-n+2) {
    margin-top: 0;
  }
  .s_publications .col6:not(:nth-child(-n+2)) {
    margin-top: 70px;
  }
}
@media screen and (max-width: 900px) {
  .s_publications .col6:nth-child(-n+2) {
    margin-top: 0;
  }
  .s_publications .col6:not(:nth-child(-n+2)) {
    margin-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .s_publications .col6:nth-child(-n+1) {
    margin-top: 0;
  }
  .s_publications .col6:not(:nth-child(-n+1)) {
    margin-top: 30px;
  }
}
.s_publications .wrapper {
  text-align: center;
  margin-top: 90px;
}
@media screen and (max-width: 1200px) {
  .s_publications .wrapper {
    margin-top: 70px;
  }
}
@media screen and (max-width: 900px) {
  .s_publications .wrapper {
    margin-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .s_publications .wrapper {
    margin-top: 30px;
  }
}

.s_contact {
  position: relative;
  z-index: 1;
}
.s_contact .w1200 {
  position: relative;
  height: calc(100% + 120px);
  margin-top: -120px;
  background: #fff;
}
@media screen and (max-width: 1200px) {
  .s_contact .w1200 {
    height: calc(100% - 80px);
    margin-top: -80px;
  }
}
@media screen and (max-width: 1024px) {
  .s_contact .w1200 {
    height: calc(100% - 50px);
    margin-top: -50px;
  }
}
@media screen and (max-width: 950px) {
  .s_contact .w1200 {
    height: 100%;
    margin-top: 0;
  }
}
.s_contact .itemLeft {
  width: 50%;
}
@media screen and (max-width: 600px) {
  .s_contact .itemLeft {
    width: 100%;
  }
}
.s_contact .itemLeft p {
  line-height: 1.5;
}
.s_contact .itemLeft p:not(.fax), .s_contact .itemLeft a {
  font-family: "gtWalsheim", sans-serif;
  color: #343b48;
  font-size: 1.375rem;
}
@media screen and (max-width: 1024px) {
  .s_contact .itemLeft p:not(.fax), .s_contact .itemLeft a {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 768px) {
  .s_contact .itemLeft p:not(.fax), .s_contact .itemLeft a {
    font-size: 1.1875rem;
  }
}
.s_contact .itemLeft .tel {
  transition: opacity 300ms;
}
.s_contact .itemLeft .tel:hover {
  opacity: 0.4;
}
.s_contact .itemLeft .mail {
  box-shadow: inset 0px -1px 0 #343b48;
  transition: color 300ms, box-shadow 300ms;
}
.s_contact .itemLeft .mail:hover {
  color: rgba(52, 59, 72, 0.4);
  box-shadow: inset 0px -1px 0 rgba(52, 59, 72, 0.4);
}
.s_contact .itemLeft .fax {
  margin-top: 20px;
  color: #343b48;
}
@media screen and (max-width: 600px) {
  .s_contact .itemLeft .fax {
    margin-top: 15px;
  }
}
.s_contact .itemRight {
  width: 50%;
}
@media screen and (max-width: 600px) {
  .s_contact .itemRight {
    width: 100%;
    margin-top: 30px;
  }
}
.s_contact .itemRight p {
  margin-bottom: 22px;
  color: #343b48;
  line-height: 1.5;
  font-size: 1.375rem;
}
@media screen and (max-width: 1024px) {
  .s_contact .itemRight p {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 768px) {
  .s_contact .itemRight p {
    font-size: 1.1875rem;
  }
}
@media screen and (max-width: 768px) {
  .s_contact .itemRight p {
    max-width: 250px;
  }
}
@media screen and (max-width: 600px) {
  .s_contact .itemRight p {
    margin: 0 0 15px;
  }
}
.s_contact .formWrapper {
  margin-top: 90px;
}
@media screen and (max-width: 1200px) {
  .s_contact .formWrapper {
    margin-top: 70px;
  }
}
@media screen and (max-width: 900px) {
  .s_contact .formWrapper {
    margin-top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .s_contact .formWrapper {
    margin-top: 30px;
  }
}
.s_contact .formWrapper h3 {
  margin-bottom: 50px;
}
@media screen and (max-width: 1024px) {
  .s_contact .formWrapper h3 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .s_contact .formWrapper h3 {
    margin-bottom: 30px;
  }
}

.s_partners .itemPrimary {
  width: calc(100% - 650px);
}
@media screen and (max-width: 1300px) {
  .s_partners .itemPrimary {
    width: calc(100% - 550px);
  }
}
@media screen and (max-width: 1100px) {
  .s_partners .itemPrimary {
    width: calc(100% - 475px);
  }
}
@media screen and (max-width: 900px) {
  .s_partners .itemPrimary {
    width: calc(100% - 425px);
  }
}
@media screen and (max-width: 768px) {
  .s_partners .itemPrimary {
    width: 100%;
    text-align: center;
  }
}
.s_partners .itemPrimary .scb img {
  width: 325px;
  height: 325px;
}
@media screen and (max-width: 1300px) {
  .s_partners .itemPrimary .scb img {
    width: 300px;
    height: 300px;
  }
}
@media screen and (max-width: 1200px) {
  .s_partners .itemPrimary .scb img {
    width: 250px;
    height: 250px;
  }
}
@media screen and (max-width: 900px) {
  .s_partners .itemPrimary .scb img {
    width: 200px;
    height: 200px;
  }
}
.s_partners .itemSecondary {
  width: 650px;
}
@media screen and (max-width: 1300px) {
  .s_partners .itemSecondary {
    width: 550px;
  }
}
@media screen and (max-width: 1100px) {
  .s_partners .itemSecondary {
    width: 475px;
  }
}
@media screen and (max-width: 900px) {
  .s_partners .itemSecondary {
    width: 425px;
  }
}
@media screen and (max-width: 768px) {
  .s_partners .itemSecondary {
    width: 100%;
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .s_partners .itemSecondary {
    margin-top: 30px;
  }
}
.s_partners .itemSecondary .col4 {
  display: flex;
  height: 190px;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1300px) {
  .s_partners .itemSecondary .col4 {
    height: 170px;
  }
}
@media screen and (max-width: 1024px) {
  .s_partners .itemSecondary .col4 {
    height: 150px;
  }
}
@media screen and (max-width: 768px) {
  .s_partners .itemSecondary .col4 {
    height: 130px;
  }
}
.s_partners .itemSecondary .col4.bb {
  border-bottom: 1px solid #d6d8da;
}
@media screen and (max-width: 425px) {
  .s_partners .itemSecondary .col4.bb_425 {
    border-bottom: 1px solid #d6d8da;
  }
}
.s_partners .itemSecondary a {
  text-align: center;
}
.s_partners .itemSecondary a img {
  width: 95%;
}
@media screen and (max-width: 1100px) {
  .s_partners .itemSecondary a img {
    width: 90%;
  }
}
@media screen and (max-width: 900px) {
  .s_partners .itemSecondary a img {
    width: 85%;
  }
}
.s_partners .itemSecondary .joliette img {
  width: 60%;
}
.s_partners .itemSecondary .decoStyle img {
  width: 70%;
}
.s_partners .itemSecondary .mrcJoliette img {
  width: 90%;
}
.s_partners .itemSecondary .lanaudiere img {
  width: 85%;
}
.s_partners .itemSecondary .tourimeMrcDeJoliette img {
  width: 85%;
}
.s_partners .itemSecondary .cultureLanaudiere img {
  width: 85%;
}

.s_form h4 {
  margin-top: 0;
}
.s_form .btnAddArtWork {
  color: #343b48;
  margin-right: 20px;
  padding-right: 60px;
}
.s_form .btnAddArtWork:before {
  background: #fff;
  border: 1px solid rgba(52, 59, 72, 0.2);
}
.s_form .btnAddArtWork span {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}
.s_form .btnAddArtWork span:before, .s_form .btnAddArtWork span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: #f66f62;
  transform: translate(-50%, -50%);
  transition: background 300ms;
}
.s_form .btnAddArtWork span:before {
  width: 16px;
  height: 2px;
}
.s_form .btnAddArtWork span:after {
  width: 2px;
  height: 16px;
}
.s_form .btnRemoveArtWork {
  margin-bottom: 15px;
}
.s_form .btnClearInput {
  margin-bottom: 30px;
}
.s_form .artwork-title {
  line-height: 2.5;
}

#page-home .s_banner {
  position: relative;
}
@media screen and (max-width: 600px) {
  #page-home .s_banner {
    height: 475px;
  }
}
#page-home .s_banner .slickBanner {
  margin-bottom: 0;
  font-size: 0;
}
#page-home .s_banner .slickBanner picture, #page-home .s_banner .slickBanner figure {
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 600px;
}
@media screen and (max-width: 600px) {
  #page-home .s_banner .slickBanner picture, #page-home .s_banner .slickBanner figure {
    min-height: 500px;
  }
}
#page-home .s_banner .slickBanner picture:before, #page-home .s_banner .slickBanner figure:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 400px;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
#page-home .s_banner .slickBanner picture:after, #page-home .s_banner .slickBanner figure:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(52, 59, 72, 0.5);
}
#page-home .s_banner .slickBanner .text {
  display: flex;
  position: absolute;
  height: calc(var(--vh, 1vh) * 100 - 185px);
  min-height: 400px;
  margin-top: 105px;
  top: 0;
  left: 0;
  align-items: center;
}
@media screen and (max-width: 768px) {
  #page-home .s_banner .slickBanner .text {
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 90px;
  }
}
@media screen and (max-width: 600px) {
  #page-home .s_banner .slickBanner .text {
    padding-right: 25px;
    padding-left: 25px;
    min-height: 300px;
    margin-top: 80px;
  }
}
#page-home .s_banner .slickBanner .text h1 {
  max-width: 620px;
  font-size: 115px;
}
@media screen and (max-height: 750px) {
  #page-home .s_banner .slickBanner .text h1 {
    font-size: 95px;
    max-width: 575px;
  }
}
@media screen and (max-width: 1440px) {
  #page-home .s_banner .slickBanner .text h1 {
    font-size: 95px;
    max-width: 540px;
  }
}
@media screen and (max-height: 650px) {
  #page-home .s_banner .slickBanner .text h1 {
    font-size: 75px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .s_banner .slickBanner .text h1 {
    font-size: 75px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .s_banner .slickBanner .text h1 {
    font-size: 65px;
    max-width: 450px;
  }
}
@media screen and (max-width: 425px) {
  #page-home .s_banner .slickBanner .text h1 {
    font-size: 55px;
  }
}
@media screen and (max-width: 375px) {
  #page-home .s_banner .slickBanner .text h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 320px) {
  #page-home .s_banner .slickBanner .text h1 {
    font-size: 45px;
  }
}
#page-home .s_banner .slickBanner .text .btn {
  margin-top: 40px;
}
@media screen and (max-height: 650px) {
  #page-home .s_banner .slickBanner .text .btn {
    margin-top: 25px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .s_banner .slickBanner .text .btn {
    margin-top: 25px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .s_banner .slickBanner .text .btn {
    margin-top: 20px;
  }
}
#page-home .s_banner .shape {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 768px) {
  #page-home .s_banner .shape {
    width: 300px;
  }
}
#page-home .s_banner .slickBannerDots {
  position: absolute;
  top: 50%;
  right: 80px;
  transform: translateY(-50%);
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  #page-home .s_banner .slickBannerDots {
    right: 50px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .s_banner .slickBannerDots {
    right: 30px;
  }
}
@media screen and (max-width: 600px) {
  #page-home .s_banner .slickBannerDots {
    display: none;
  }
}
#page-home .s_banner .slickBannerDots ul {
  position: relative;
  bottom: 0;
}
#page-home .s_banner .slickBannerDots ul li {
  display: block;
  width: 12px;
  height: 12px;
  margin: 0;
}
#page-home .s_banner .slickBannerDots ul li:not(:last-child) {
  margin: 0 0 20px 0;
}
#page-home .s_banner .slickBannerDots ul li.slick-active button {
  background: #fff;
}
#page-home .s_banner .slickBannerDots ul li button {
  position: absolute;
  width: inherit;
  height: inherit;
  padding: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: background 300ms;
}
#page-home .s_banner .slickBannerDots ul li button:hover {
  background: #fff;
}
#page-home .s_banner .slickBannerDots ul li button:before {
  display: none;
}
#page-home .s_banner .scrollDown {
  display: flex;
  position: absolute;
  height: 50px;
  right: 80px;
  bottom: 30px;
  font: 0.75rem/1.5 "gtWalsheim", sans-serif;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  align-items: center;
  z-index: 1;
  white-space: nowrap;
}
@media screen and (max-width: 1024px) {
  #page-home .s_banner .scrollDown {
    right: 50px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .s_banner .scrollDown {
    right: 30px;
    bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  #page-home .s_banner .scrollDown {
    right: 25px;
  }
}
#page-home .s_banner .scrollDown :not(.arrow) {
  white-space: normal;
}
@media screen and (max-width: 425px) {
  #page-home .s_banner .scrollDown :not(.arrow) {
    width: 142px;
  }
}
#page-home .s_banner .scrollDown .arrow {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-left: 20px;
  background: #fff;
  vertical-align: middle;
}
@media screen and (max-width: 425px) {
  #page-home .s_banner .scrollDown .arrow {
    margin-left: 10px;
  }
}
#page-home .s_banner .scrollDown .arrow:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-top-color: #f66f62;
}
#page-home .s_adventure {
  position: relative;
  background: #343b48;
}
#page-home .s_adventure .background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
#page-home .s_adventure .background:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #2a3037 15%, rgba(42, 48, 55, 0) 75%);
}
#page-home .s_adventure .content {
  position: relative;
  padding-top: 430px;
  z-index: 1;
}
@media screen and (max-width: 1500px) {
  #page-home .s_adventure .content {
    padding-top: 480px;
  }
}
@media screen and (max-width: 1400px) {
  #page-home .s_adventure .content {
    padding-top: 450px;
  }
}
@media screen and (max-width: 1300px) {
  #page-home .s_adventure .content {
    padding-top: 400px;
  }
}
@media screen and (max-width: 1200px) {
  #page-home .s_adventure .content {
    padding-top: 350px;
  }
}
@media screen and (max-width: 1100px) {
  #page-home .s_adventure .content {
    padding-top: 300px;
  }
}
@media screen and (max-width: 900px) {
  #page-home .s_adventure .content {
    padding-top: 250px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .s_adventure .content {
    padding-top: 180px;
  }
}
@media screen and (max-width: 600px) {
  #page-home .s_adventure .content {
    padding-top: 120px;
  }
}
#page-home .s_adventure .content .title {
  display: flex;
  align-items: center;
  padding-left: 30px;
}
@media screen and (max-width: 1100px) {
  #page-home .s_adventure .content .title {
    padding-left: 0;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .s_adventure .content .title {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 500px) {
  #page-home .s_adventure .content .title {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
  }
}
@media screen and (max-width: 425px) {
  #page-home .s_adventure .content .title {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
#page-home .s_adventure .content .title h2 {
  margin-top: 20px;
  max-width: 550px;
  color: #fff;
}
#page-home .s_adventure .content .title h2 br {
  display: none;
}
@media screen and (max-width: 375px) {
  #page-home .s_adventure .content .title h2 br {
    display: block;
  }
}
@media screen and (max-width: 425px) {
  #page-home .s_adventure .content .order1 {
    order: 1;
  }
}
@media screen and (max-width: 425px) {
  #page-home .s_adventure .content .order2 {
    order: 3;
  }
}
@media screen and (max-width: 425px) {
  #page-home .s_adventure .content .order4 {
    order: 4;
  }
}
#page-home .s_adventure .content .text {
  display: flex;
  align-items: center;
  padding: 0 70px;
  background: #fff;
}
@media screen and (max-width: 1500px) {
  #page-home .s_adventure .content .text {
    padding: 0 60px;
  }
}
@media screen and (max-width: 1400px) {
  #page-home .s_adventure .content .text {
    padding: 0 50px;
  }
}
@media screen and (max-width: 1200px) {
  #page-home .s_adventure .content .text {
    padding: 0 40px;
  }
}
@media screen and (max-width: 1100px) {
  #page-home .s_adventure .content .text {
    padding: 0 25px;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .s_adventure .content .text {
    padding: 0 70px;
  }
}
@media screen and (max-width: 900px) {
  #page-home .s_adventure .content .text {
    padding: 0 50px;
  }
}
@media screen and (max-width: 700px) {
  #page-home .s_adventure .content .text {
    padding: 0 30px;
  }
}
@media screen and (max-width: 600px) {
  #page-home .s_adventure .content .text {
    padding: 60px;
  }
}
@media screen and (max-width: 500px) {
  #page-home .s_adventure .content .text {
    padding: 60px 20px;
  }
}
@media screen and (max-width: 425px) {
  #page-home .s_adventure .content .text {
    order: 2;
  }
}
#page-home .s_adventure .content .text h6 {
  margin-bottom: 20px;
  font-size: 1.4375rem;
  text-transform: none;
  letter-spacing: normal;
}
#page-home .s_adventure .content .text p {
  line-height: 2;
}
@media screen and (max-width: 1300px) {
  #page-home .s_adventure .content .text p {
    line-height: 1.5;
  }
}
@media screen and (max-width: 1024px) {
  #page-home .s_adventure .content .text p {
    line-height: 2;
  }
}
@media screen and (max-width: 768px) {
  #page-home .s_adventure .content .text p {
    line-height: 1.5;
  }
}
#page-home .s_experience {
  position: relative;
  background: #343b48;
}
#page-home .s_experience .shape {
  position: absolute;
  top: -100px;
  left: 0;
}
#page-home .s_experience .content {
  position: relative;
  z-index: 1;
}
#page-home .s_experience h3 {
  color: #fff;
  margin-bottom: 50px;
  text-align: center;
}
@media screen and (max-width: 1400px) {
  #page-home .s_experience h3 {
    margin-bottom: 30px;
  }
}
#page-home .s_experience .p_navigation {
  margin-top: 70px;
}
@media screen and (max-width: 1024px) {
  #page-home .s_experience .p_navigation {
    margin-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  #page-home .s_experience .p_navigation {
    margin-top: 30px;
  }
}
#page-home .s_experience .b_experience .text {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1000ms, transform 0ms cubic-bezier(0.215, 0.61, 0.355, 1) 1000ms;
}
#page-home .s_experience .b_experience .btn {
  opacity: 0;
  transition: opacity 500ms, color 300ms;
}
#page-home .s_experience .slick-current .text, #page-home .s_experience .slickAnimate .text {
  opacity: 1;
  transform: none;
  transition: opacity 1000ms, transform 1000ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
#page-home .s_experience .slick-current .btn, #page-home .s_experience .slickAnimate .btn {
  opacity: 1;
  transition: opacity 500ms linear 500ms, color 300ms;
}
#page-home .texture {
  background: url("../images/content/textures/motif.jpg") repeat center;
}
#page-home .s_rent, #page-home .s_gallery {
  background: transparent;
}

.dropzone {
  border: 1px solid rgba(52, 59, 72, 0.2);
  border-radius: 40px;
  font-family: "helveticaNeue", sans-serif !important;
  color: #343b48 !important;
}
.dropzone .dropzone .dz-message .dz-button {
  color: #343b48 !important;
}
.dropzone .dz-preview .dz-image img {
  transition: transform 300ms;
}
.dropzone .dz-preview:hover .dz-image img {
  -webkit-filter: none !important;
  filter: none !important;
}
.dropzone .dz-preview .dz-progress {
  border-radius: 2px;
  height: 10px;
}
.dropzone .dz-preview .dz-progress .dz-upload {
  background: #f66f62;
}
.dropzone .tonBoutonX {
  position: absolute;
  width: 30px;
  height: 50px;
  top: 37px;
  right: 50px;
  opacity: 0;
  cursor: pointer;
}
.dropzone .tonBoutonX:before, .dropzone .tonBoutonX:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #fff;
}
.dropzone .tonBoutonX:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.dropzone .tonBoutonX:after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.dropzone .dz-remove {
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  z-index: 99;
  text-decoration: none !important;
  transition: background-color 300ms;
}
.dropzone .dz-remove:before, .dropzone .dz-remove:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  top: 50%;
  left: 50%;
  background: #f66f62;
}
.dropzone .dz-remove:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.dropzone .dz-remove:after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.dropzone .dz-remove:hover {
  cursor: pointer;
  background-color: transparent;
}
.dropzone .dz-remove i {
  font-size: 10px;
  font-style: normal;
  font-family: sans-serif;
  font-weight: bold;
  color: white;
}
.dropzone .dz-remove i:hover {
  cursor: pointer;
}